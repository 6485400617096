
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-use';
import { stripeTheme, stripeThemeFonts } from '../../../core/theme';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);

export default function WHSubscribeWrapper() {
  const {state} = useLocation();
  const [clientSecret] = useState<string>(state.clientSecret ? state.clientSecret : state.usr.clientSecret);
  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
        appearance: stripeTheme,
        fonts: stripeThemeFonts
      }}
    >
      <Outlet/>
    </Elements>
  )

}