import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { object, string } from 'yup';
import 'yup-phone-lite';
import PhoneTermsText from './PhoneTermsText';


let phoneSchema: any;
try {
  phoneSchema = object({
    phone: (string() as any)
      .phone('US', 'Please enter a valid US phone number')
      .required('Phone number is required')
  });
} catch (e: any) {
  console.error('Phone Regex Error');
  throw e;
}

export interface IPhoneForm {
  onSubmit(phone: string): void;

  phone?: string | null;
  label: string;
  wait: boolean;
  showTerms: boolean;
  alt?: boolean;
}

export default function PhoneForm(props: IPhoneForm) {
  const {onSubmit, wait, showTerms, label, phone, alt} = props;
  const [typing, setTyping] = useState(false);

  const formik = useFormik({
    initialValues: {
      phone: phone ? phone : ''
    },
    validationSchema: phoneSchema,
    onSubmit: (values) => {
      console.log(values);
      const {phone} = values;
      const formattedPhone = phone.replace(/ /g, '')
        .replace(/-/g, '')
        .replace(/\(/g, '')
        .replace(/\)/g, '')
        .replace('.', '')
        .replace('+1', '');
      if (formattedPhone.length > 10) {
        formik.setErrors({phone: `Phone number ${formattedPhone} is not a valid 10-digit us number`});
      } else {
        console.log(`+1${formattedPhone}`);
        onSubmit(`+1${formattedPhone}`);
      }
      // @TODO validate phone
      // if (formattedPhone.charAt(0) === '+') {
      //   const sub
      // }
    }
  });

  useEffect(() => {
    console.log(phone);
    formik.setFieldValue('phone', phone);
  }, [phone]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        sx={{
          maxWidth: '550px',
          width: '100%',
          '& > :not(:last-child)': {
            // marginBottom: 24
          }
        }}
      >

        <div style={{maxHeight: 75}}>

          <TextField
            id="phone"
            name="phone"
            variant="filled"
            label="Phone"
            placeholder="Phone 206 444-4444"
            value={formik.values.phone}
            onChange={(e: React.ChangeEvent<any>) => {
              console.log(e.target.value);
              formik.handleChange(e);
            }}
            onBlur={(e: React.FocusEvent) => {
              setTyping(false);
              formik.handleBlur(e);
            }}
            onFocus={() => {
              setTyping(true);
            }}
            error={(formik.touched.phone || formik.submitCount > 0) && Boolean(formik.errors.phone) && !typing}
            helperText={(formik.touched.phone || formik.submitCount > 0) && formik.errors.phone && !typing ? formik.errors.phone :
              (showTerms ? <PhoneTermsText/> : '')}
            sx={{
              // backgroundColor: theme.palette.primary.contrastText
              color: (theme) => alt ? 'inherit' : theme.palette.primary.contrastText,
              width: {
                xs: 320,
                sm: 350
              },
              // '& .MuiOutlinedInput-root': {
              //   color: (theme) => theme.palette.primary.contrastText,
              // }
            }}
            inputProps={{
              autocomplete: 'tel',
              inputMode: 'tel',
              type: 'tel'
            }}
            type="tel"
          />
        </div>

        <Collapse in={!wait}>
          <Button
            variant="contained"
            type="submit"
            color="info"
            sx={{
              ...(alt ? {mt: 4} : {
                backgroundColor: '#fff',
                color: (theme) => theme.palette.text.primary,
                marginTop: 6,
                '&:hover': {
                  backgroundColor: '#fff',
                  color: (theme) => theme.palette.text.primary,
                },
                '&:active': {
                  backgroundColor: '#fff',
                  color: (theme) => theme.palette.text.primary,
                }
              })
            }}
            disabled={formik.touched.phone && !!formik.errors.phone}
          >
            {label}
          </Button>
        </Collapse>
        {/*<div style={{ width: '100%', height: 50 }}/>*/}
      </Grid>
    </form>
  );
}
