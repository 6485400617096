import { Address, IProfile } from '@jumpstart/core';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { User, UserCredential } from 'firebase/auth';
import { collection, doc, DocumentReference, Query, setDoc, UpdateData, updateDoc } from 'firebase/firestore';
import { Formik } from 'formik';
import { values } from 'lodash';
import moment from 'moment-timezone';
import React, { Fragment, useEffect, useState } from 'react';
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';
import parsePhoneNumber from 'libphonenumber-js'
import { useParams } from 'react-router-dom';
import { array, boolean, mixed, object, string } from 'yup';
import 'yup-phone-lite';
import { db } from '../../../core/firebase';
import { StripePrice, StripeProduct } from '../../../core/schemas';
import { COLORS, stripeTheme, stripeThemeFonts } from '../../../core/theme';
import ProfileService, { IHumanParse } from '../../../services/profile-service';
import StripeService from '../../../services/stripe-service';
import { useApproxZipcode } from '../../../core/providers/WHProvider';
import { kingCountyZipcodes } from '../../../core/utils/zipcodes/zips';
import { IHome, ISubscribeFormData, ISubscriptionSetupData, SetupForm } from './forms';
import { discountPriceObj, EnhancedStripeProduct, HOME_SECURITY_PRODUCTS } from './home-security-products';
import SubscriptionEstimateDetails from './SubscriptionEstimateDetails';
import CheckoutView from './widgets/CheckoutView';
import CheckoutViewPrepping from './widgets/CheckoutViewPrepping';
import LoadingCheckout from './widgets/LoadingCheckout';
import QuickPhoneSignin from './widgets/QuickPhoneSignin';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);


const subscribeSetupValidationSchema = object({
  name: string()
    .required('Name is required'),
  phone: (string() as any)
    .phone('US', 'Please enter a valid US phone')
    .required('Phone number is required'),
  email: string()
    .label('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  homes: array(object({
    name: string()
      .label('Enter your')
      .required('Email is required'),
    zip: string()
      .required('Zipcode is required')
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(5, 'Must be exactly 5 digits')
      .max(5, 'Must be exactly 5 digits'),
    hasDigitalLock: boolean()
      .required('Answer missing'),
    line1: mixed().required('Street address required'),
    line2: mixed().optional().nullable(),
    province: mixed().required('City required'),
    region: mixed().required('State required'),
    country: mixed().required('Country required'),
    accessDetails: string().when('hasDigitalLock', {
      is: true,
      then: (schema) => schema.required('Must provide access details'),
      otherwise: (schema) => schema.optional().nullable(),
    }),
    lockProductId: string().when('hasDigitalLock', {
      is: false,
      then: (schema) => schema.required('Must select an approved digital lock'),
      otherwise: (schema) => schema.optional().nullable()
    })
  }))
});


export default function WHSubscribeSetupScreen(props: { user: User }) {
  console.log('USER UID:', props.user.uid);
  const [initialSignupData] = useDocumentData<{ email?: string; phone?: string }>(doc(db,
    'wh-signups',
    props.user.uid)
  );
  const [user, setUser] = useState<User>(props.user);
  const [formData, setFormData] = useState<null | ISubscribeFormData>(null);
  const params = useParams<{ product: string; price: string; }>();
  if (!params.price || !params.product) {
    throw new Error('No price or product params');
  }
  const productCollectionBase = process.env.REACT_APP_ENV === 'production' ? 'stripe-products' : 'stripe-dev-products';
  const [product] = useDocumentData<StripeProduct>(doc(db,
    `${productCollectionBase}/${params.product}`) as DocumentReference<StripeProduct>);
  const [price] = useDocumentData<StripePrice>(doc(db,
    `${productCollectionBase}/${params.product}/prices/${params.price}`) as DocumentReference<StripePrice>);
  const approxZip = useApproxZipcode();
  const [subscriptionData, setSubscriptionData] = useState<ISubscriptionSetupData | null>(null);
  const [error, setError] = useState<null | Error>(null);
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState<any>(null);
  // const profile: IProfile | undefined = useProfile();
  const desktop = useMediaQuery('(min-width:600px)');
  const [submitting, setSubmitting] = useState(false);
  const products = (process.env.REACT_APP_ENV === 'production' ? HOME_SECURITY_PRODUCTS.live : HOME_SECURITY_PRODUCTS.test);

  const savedProducts = (values: ISubscribeFormData) => (values.homes ? values.homes : [])
    .filter((home: IHome) => !!home.lockProductId)
    .reduce((accumulator: EnhancedStripeProduct[], home: IHome) => {
      const index = products.findIndex((p) => p.id === home.lockProductId);
      if (index > -1) {
        accumulator.push(products[index]);
      }
      return accumulator;
    }, []);

  const createCustomer = (data: { phone?: undefined | string, email?: undefined | string }, user: User) => {
    if (user.isAnonymous) {
      console.error('Cannot create a customer without being verified');
      return;
    }
    StripeService.createCustomer({profileId: user.uid, ...data})
      .then(({customer, status}) => {
        console.log('Customer, status:', status);
        setCustomer(customer);
      })
      .catch((err: Error) => {
        console.error('Failed to create customer', err.message);
        setError(err);
      });
  }

  const handleFormData = (values: ISubscribeFormData) => {
    if (!desktop) {
      window.scrollTo(0, 0);
    }
    console.log('Submit')
    if (submitting) {
      return;
    }
    setFormData(values);
  };

  const handleCreateSubscription = async () => {
    const priceId = price ? price.id : undefined;
    if (!customer) {
      throw new Error('No customer profile');
    }
    if (!priceId) {
      throw new Error('No price!');
    }
    if (!formData) {
      throw new Error('No form data');
    }
    if (submitting) {
      console.log('Already submitting...');
      return;
    }
    setSubmitting(true);
    if (formData.homes) {
      for (let i = 0; i < formData.homes.length; i++) {
        setDoc(doc(db, `wh-profiles/${user.uid}/homes/home-${i}`), formData.homes[i])
          .then(() => {
            console.log('Home built', i);
          })
      }
    }
    const updates: UpdateData<IProfile> = {};
    updates.email = formData.email;
    updates.phone = formData.phone;
    if (formData.phone) {
      const phone = parsePhoneNumber(formData.phone, 'US');
      if (phone) {
        updates.phone = phone.number;
      } else {
        console.error('Could not parse phone number!')
        updates.phone = formData.phone;
      }
    }
    const parse: IHumanParse = ProfileService.parseFullName(formData.name ? formData.name : '')
    if (parse.firstName) {
      updates.firstName = parse.firstName
    }
    if (parse.lastName) {
      updates.lastName = parse.lastName;
    }
    if (parse.middleName) {
      updates.middleName = parse.middleName;
    }
    if (formData.homes && formData.homes[0]) {
      updates.address = formData.homes[0];
    }
    const customerIdKey = process.env.REACT_APP_ENV === 'production' ? 'stripeCustomerId' : 'stripeCustomerIdDev';
    // const customerId = profile && profile.payment ? (profile.payment as any)[customerIdKey] : undefined;
    (updates as any)[`payment.${customerIdKey}`] = customer.id;
    (updates as any).timezone = formData.timezone;
    await Promise.all([
      updateDoc(doc(db, `wh-profiles/${user.uid}`), updates),
      StripeService
        .createSubscription({
          customerId: customer.id,
          priceId,
          additionalPriceIds: savedProducts(formData)
            .filter(p => !!discountPriceObj(p))
            .map(p => (discountPriceObj(p) as StripePrice).id)
        })
        .then(({subscriptionId, clientSecret}) => {
          console.log('Subscription created');
          setSubscriptionData({subscriptionId, clientSecret});
        })
    ])
      .then(() => {
        setSubmitting(false);
      })
      .catch((err: Error) => {
        setError(err);
        setSubmitting(false);
      });
  }

  const handleLogin = async (result: UserCredential) => {
    if (!desktop) {
      window.scrollTo(0, 0);
    }
    setUser(result.user);
  }

  useEffect(() => {
    if (initialSignupData && user.phoneNumber && !user.isAnonymous) {
      createCustomer(initialSignupData, user);
    }
  }, [initialSignupData, user]);

  useEffect(() => {
    if (formData && user.phoneNumber && !user.isAnonymous) {
      handleCreateSubscription()
        .then(() => {
          console.log('Subscription created');
        });
    }

  }, [formData, user]);


  // if (subscriptionData) {
  //   console.log('Got subscription data, navigate to subscription page...')
  //   return (<Navigate to={`/j/plans/checkout`} state={{...subscriptionData}}/>);
  // }


  if (!initialSignupData) {
    return (<LoadingCheckout desktop={desktop}/>);
  }

  if (!formData) {
    return (
      <Formik
        onSubmit={handleFormData}
        initialValues={{
          name: '',
          phone: initialSignupData.phone,
          email: initialSignupData.email,
          timezone: moment.tz.guess(),
          homes: [
            {
              ...Address.defaultProps(),
              name: 'Primary Home',
              accessDetails: '',
              zip: approxZip
            }
          ]
        }}
        validationSchema={subscribeSetupValidationSchema}
      >
        {(formikProps) =>

          <Fragment>
            {/*<WHHeader showUseCases={false} showTitle={false} logo={'Logo.png'}/>*/}
            <Grid
              container
              direction={desktop ? 'row' : 'column'}
              wrap="nowrap"
              sx={{
                '& > :not(:last-child)': {
                  marginBottom: desktop ? 'unset' : '1.5rem'
                }
              }}
            >
              <Grid item xs={desktop ? 6 : 12} sx={{backgroundColor: desktop ? COLORS.gray6 : 'inherit'}}>
                {error && <Alert severity="error" onClose={() => setError(null)}>{error.message}</Alert>}
                {product && price && <SubscriptionEstimateDetails
                    product={product}
                    price={price}
                    items={savedProducts(formikProps.values)}
                    desktop={desktop}
                    loading={loading}
                />}
              </Grid>

              <Grid
                item
                xs={desktop ? 6 : 12}
                sx={{
                  minHeight: '100vh',
                  backgroundColor: '#fff',
                  '&:before': desktop ? {
                    content: '" "',
                    boxShadow: '15px 0 30px 0 rgb(0 0 0 / 18%)',
                    height: '100%',
                    width: '50%',
                    background: '#fff',
                    position: 'fixed',
                    top: 0,
                    right: 0,
                    animationFillMode: 'both',
                    transformOrigin: 'right'
                  } : {}
                }}
              >
                <SetupForm
                  formikProps={formikProps}
                  desktop={desktop}
                  submitting={submitting}
                />
              </Grid>

            </Grid>

          </Fragment>
        }
      </Formik>
    )
  } else if (!user.phoneNumber) {
    return (
      <Grid
        container
        direction={desktop ? 'row' : 'column'}
        wrap="nowrap"
        sx={{
          '& > :not(:last-child)': {
            marginBottom: desktop ? 'unset' : '1.5rem'
          }
        }}
      >
        <Grid item xs={desktop ? 6 : 12} sx={{backgroundColor: desktop ? COLORS.gray6 : 'inherit'}}>
          {error && <Alert severity="error" onClose={() => setError(null)}>{error.message}</Alert>}
          {product && price && <SubscriptionEstimateDetails
              product={product}
              price={price}
              items={savedProducts(formData)}
              desktop={desktop}
              loading={loading}
          />}
        </Grid>

        <Grid
          item
          xs={desktop ? 6 : 12}
          sx={{
            minHeight: '100vh',
            backgroundColor: '#fff',
            '&:before': desktop ? {
              content: '" "',
              boxShadow: '15px 0 30px 0 rgb(0 0 0 / 18%)',
              height: '100%',
              width: '50%',
              background: '#fff',
              position: 'fixed',
              top: 0,
              right: 0,
              animationFillMode: 'both',
              transformOrigin: 'right'
            } : {}
          }}
        >
          <QuickPhoneSignin
            onError={setError}
            onSuccess={handleLogin}
            initialPhone={formData.phone as string}
            desktop={desktop}
          />
        </Grid>
      </Grid>
    );
  } else if (submitting && product && price) {
    return (
      <CheckoutViewPrepping
        product={product as StripeProduct}
        price={price as StripePrice}
        items={savedProducts(formData)}
        desktop={desktop}
        user={user}
      />
    );
  } else if (subscriptionData) {
    return (
      <Elements
        stripe={stripePromise}
        options={{
          clientSecret: subscriptionData.clientSecret,
          appearance: stripeTheme,
          fonts: stripeThemeFonts
        }}
      >
        <CheckoutView
          user={user}
          subscriptionId={subscriptionData.subscriptionId}
          clientSecret={subscriptionData.clientSecret}
          cachedData={{
            product: product as StripeProduct,
            price: price as StripePrice,
            items: savedProducts(formData)
          }}
          desktop={desktop}
        />
      </Elements>
    );
  } else {
    console.log(user);
    return (<LoadingCheckout desktop={desktop}/>);
  }
}
