import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Zoom from '@mui/material/Zoom';
import { User } from 'firebase/auth';
import { doc } from 'firebase/firestore';
import React, { Fragment, useEffect, useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { Navigate, useNavigate } from 'react-router-dom';
import 'yup-phone-lite';
import { IPlan, PLANS, whitelistedZipcodes } from '../../core/config';
import { db } from '../../core/firebase';
import { useApproxZipcode } from '../../core/providers';
import { COLORS } from '../../core/theme';
import LoadingBackdrop from '../../widgets/LoadingBackdrop';
import CustomSwitch from '../../widgets/material-extensions/CustomSwitch';
import PlanCard from './PlanCard';
import ServicesModal from './ServicesModal';
import WHAvailabilityForm from './WHAvailabilityForm';
import WHHeader from './WHHeader';

export default function WHPlansScreen(props: { user: User }) {
  const [open, setOpen] = useState(false);
  const [data, dataLoading] = useDocumentData<{ email?: string; phone?: string }>(doc(db,
    'wh-signups',
    props.user.uid));
  const navigate = useNavigate();
  const desktop = useMediaQuery('(min-width:600px)');
  const [loading, setLoading] = useState<string | null>(null);
  const [enterZip, setEnterZip] = useState(false);
  const [available, setAvailable] = useState(false);
  const approxZipcode = useApproxZipcode();
  const [zipcode, setZipcode] = useState(approxZipcode);
  const [showNotAvailableCard, setShowNotAvailableCard] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [mode, setMode] = useState<'monthly' | 'yearly'>('yearly');

  const openSubscription = (plan: IPlan) => {
    const productId = process.env.REACT_APP_ENV === 'production' ? plan.id : plan.idTest;
    const monthlyPriceId = process.env.REACT_APP_ENV === 'production' ? plan.priceMonthlyId : plan.priceMonthlyIdTest;
    const yearlyPriceId = process.env.REACT_APP_ENV === 'production' ? plan.priceYearlyId : plan.priceYearlyIdTest;
    navigate(`/j/plans/setup/${productId}/${mode === 'monthly' ? monthlyPriceId : yearlyPriceId}`);
  };

  const handleZipChange = (z: string | null) => {
    setZipcode(z);
    setEnterZip(false);
  };

  useEffect(() => {
    if (zipcode) {
      setAvailable(whitelistedZipcodes.indexOf(zipcode) > -1);
    } else {
      setAvailable(false);
    }
  }, [zipcode]);

  useEffect(() => {
    setZipcode(approxZipcode);
  }, [approxZipcode]);

  if (!dataLoading && !data) {
    console.error('Missing required location state (phone or email), navigating back...');
    return (<Navigate to={`/j/i`}/>);
  }

  return (
    <Fragment>
      <WHHeader showUseCases={false} showTitle={false}/>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        justifyContent="center"
        alignItems="center"
        sx={{
          padding: '0 24px',
          '& > :not(:last-child)': {
            marginBottom: '0.5rem'
          }
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color: '#fff', textAlign: 'center', fontSize: {
              xs: '2rem',
              md: '2.25rem'
            }
          }}
        >
          Memberships
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: '#fff',
            textAlign: 'center',
            fontSize: 18,
            opacity: 0.7,
            lineHeight: 1.75
          }}
        >
          Select a membership plan that best meet your needs
        </Typography>
      </Grid>

      {error && <Alert severity="error" onClose={() => setError(null)}>{error.message}</Alert>}

      <Grid
        container
        direction="column"
        wrap="nowrap"
        sx={{
          padding: '0 24px',
          '& > :not(:last-child)': {
            marginBottom: 3
          }
        }}
      >
        <Button
          size="small"
          startIcon={
            zipcode ?
              available ? <CheckIcon sx={{color: theme => theme.palette.success.light}}/> : <CloseIcon color="error"/>
              : null
          }
          disableRipple
          sx={{
            textDecoration: 'underline',
            textAlign: 'left',
            maxWidth: 'unset',
            padding: 0,
            color: theme => available ? theme.palette.success.light : theme.palette.error.light
          }}
          onClick={() => setEnterZip(true)}
        >
          {zipcode ? available ? 'Membership available in your area' : 'Membership not available in your area' : ''}{zipcode ? ` (${zipcode})` : 'Enter Zipcode'}
        </Button>
        {/*<Grid*/}
        {/*  container*/}
        {/*  direction="column"*/}
        {/*  alignItems="center"*/}
        {/*  justifyContent="center"*/}
        {/*>*/}
        {/*</Grid>*/}

        <Grid container direction="row" alignItems="center" justifyContent="center">
          <Grid item sx={{width: 120}}>
            <Grid container direction="row" alignItems="center" justifyContent="flex-end">
            <Typography variant="body2" sx={{color: '#fff'}}>Monthly</Typography>
            </Grid>
          </Grid>
          <CustomSwitch
            sx={{m: 1}}
            checked={mode === 'yearly'}
            onChange={(event) => setMode(event.target.checked ? 'yearly' : 'monthly')}
          />
          <Grid item sx={{width: 120}}>
            <Grid container direction="row" alignItems="center" justifyContent="flex-start">
              <Typography variant="body2" sx={{mr: 2, color: '#fff'}}>Yearly</Typography>
              <Zoom in={mode === 'yearly'}>
                <Chip size="small" label="20% off" color="secondary"/>
              </Zoom>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={4}
          justifyContent="center"
          sx={{
            position: 'relative'
          }}
        >
          {!available && <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent={desktop ? 'center' : 'flex-start'}
              sx={{
                position: 'absolute',
                zIndex: 2,
                top: desktop ? 'unset' : 24,
                width: '100%',
                height: '100%',
                backdropFilter: 'blur(1px)'
              }}
          >
            {showNotAvailableCard && <Paper
                sx={{
                  backgroundColor: COLORS.gray2,
                  padding: '32px 24px',
                  position: 'relative'
                }}
            >
                <IconButton size="small" sx={{position: 'absolute', right: 6, top: 6}}
                            onClick={() => setShowNotAvailableCard(false)}><CloseIcon fontSize="small"
                                                                                      sx={{color: '#fff'}}/></IconButton>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    sx={{
                      '& > :not(:last-child)': {
                        marginBottom: 4
                      }
                    }}
                >
                    <Typography
                        variant="body1"
                        sx={{
                          color: '#fff',
                          marginTop: 3
                        }}
                    >
                      {zipcode ? 'Membership is not available in your area:' : 'Check availability in your area:'}
                    </Typography>
                    <Grid
                        container
                        direction="column"
                        alignItems="center"
                        sx={{
                          '& > :not(:last-child)': {
                            marginBottom: 2
                          }
                        }}
                    >
                        <Link variant="h4"
                              onClick={() => setEnterZip(true)}>{zipcode ? zipcode : 'Enter Zipcode'}</Link>
                      {zipcode && <Typography variant="caption" sx={{color: '#fff', opacity: 0.7}}>Zipcode</Typography>}
                    </Grid>
                </Grid>
            </Paper>}
          </Grid>}


          {PLANS.map((plan: IPlan, index: number) =>
            <Grid item key={`plan-${index}`}>
              <PlanCard
                {...plan}
                mode={mode}
                loading={loading === plan.id}
                onSelect={() => openSubscription(plan)}
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        direction="column"
        wrap="nowrap"
        justifyContent="center"
        alignItems="center"
        sx={{
          padding: '0 24px',
          '& > :not(:last-child)': {
            marginBottom: '0.5rem'
          }
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: '#fff',
            textAlign: 'center',
            fontSize: 14,
            opacity: 0.7,
            lineHeight: 1.75,
            fontStyle: 'italic'
          }}
        >
          Advanced services (e.g. window repairs or gutter cleaning) have additional fees. <Link
          underline="always"
          sx={{
            color: '#fff',
            textDecoration: 'underline',
          }}
          onClick={() => setOpen(true)}
        >learn more</Link>
        </Typography>
      </Grid>

      <ServicesModal open={open} onClose={() => setOpen(false)}/>

      <LoadingBackdrop open={!!loading} text="Loading Membership"/>

      <Dialog
        open={enterZip}
        onClose={() => setEnterZip(false)}
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          sx={{
            padding: 6
          }}
        >
          <WHAvailabilityForm user={props.user} approxZipcode={approxZipcode} onSubmit={handleZipChange}/>
        </Grid>
      </Dialog>
    </Fragment>
  );
}
