import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FormikErrors, FormikProps } from 'formik/dist/types';
import { MomentZone } from 'moment';
import moment from 'moment-timezone';
import React from 'react';
import InfoTip from '../../../../widgets/material-extensions/InfoTip';
import { ISubscribeFormData } from './schemas';
import { inputStyles, labelStyles } from './styles';

export interface IUserSubForm extends FormikProps<ISubscribeFormData> {
  desktop: boolean;
}

export default function UserSubForm(props: IUserSubForm) {
  const {desktop, setFieldValue, values, errors, submitCount, handleChange} = props;
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      sx={{
        '& > :not(:last-child)': {
          marginBottom: 1
        }
      }}
    >

      {desktop &&
          <Typography variant="h3" sx={{textAlign: 'left', width: '100%', maxWidth: 450}}>Membership Setup</Typography>}

      <Typography variant="subtitle2" color="text.secondary" sx={{textAlign: 'left', width: '100%', maxWidth: 450}}>Contact
        Information</Typography>
      <Paper
        elevation={1}
        sx={{
          width: '100%',
          maxWidth: 450,
          '& > *': {
            padding: '48px 24px'
          }
        }}
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          sx={{
            '& > :not(:last-child)': {
              marginBottom: 3
            }
          }}
        >
          <FormControlLabel
            sx={labelStyles}
            control={
              <TextField
                onChange={handleChange}
                id="name"
                name="name"
                variant="filled"
                value={values.name}
                placeholder={`Jane Doe`}
                error={!!errors.name && submitCount > 0}
                helperText={errors.name && submitCount > 0 ? errors.name : ''}
                sx={inputStyles}
                inputProps={{
                  autoComplete: 'cc-name'
                }}
              />
            }
            label="Your full name"
          />

          <FormControlLabel
            sx={labelStyles}
            control={
              <TextField
                onChange={handleChange}
                name="email"
                variant="filled"
                value={values.email}
                error={!!errors.email && submitCount > 0}
                helperText={errors.email && submitCount > 0 ? errors.email : ''}
                sx={inputStyles}
                inputProps={{
                  autoComplete: 'email',
                  type: 'email'
                }}
                type="email"
              />
            }
            label="Email"
          />

          <FormControlLabel
            sx={labelStyles}
            control={
              <TextField
                id="phone"
                name="phone"
                variant="filled"
                value={values.phone}
                onChange={(e: React.ChangeEvent<any>) => {
                  console.log(e.target.value);
                  handleChange(e);
                }}
                error={!!errors.phone && submitCount > 0}
                helperText={errors.phone && submitCount > 0 ? errors.phone : ''}
                placeholder="Phone 206 444-4444"
                sx={inputStyles}
                inputProps={{
                  autoComplete: 'tel',
                  inputMode: 'tel',
                  type: 'tel'
                }}
                type="tel"
              />
            }
            label={
              <InfoTip title="Your dedicated house manager will connect with you via phone">
                <Typography variant="body1">Phone</Typography>
              </InfoTip>
            }
          />


          {/*<FormControlLabel*/}
          {/*  sx={labelStyles}*/}
          {/*  control={*/}
          {/*    <TextField*/}
          {/*      id="timezone"*/}
          {/*      name="timezone"*/}
          {/*      variant="filled"*/}
          {/*      value={values.timezone}*/}
          {/*      onChange={(e: React.ChangeEvent<any>) => {*/}
          {/*        console.log(e.target.value);*/}
          {/*        handleChange(e);*/}
          {/*      }}*/}
          {/*      error={!!errors.timezone && submitCount > 0}*/}
          {/*      helperText={errors.timezone && submitCount > 0 ? errors.timezone : ''}*/}
          {/*      sx={inputStyles}*/}
          {/*      select*/}
          {/*      SelectProps={{*/}
          {/*        MenuProps: {*/}
          {/*          MenuListProps: {*/}
          {/*            sx: {*/}
          {/*              backgroundColor: '#fff'*/}
          {/*            }*/}
          {/*          }*/}
          {/*        }*/}

          {/*      }}*/}
          {/*    >*/}
          {/*      {moment.tz.zonesForCountry('US').map((option) => (*/}
          {/*        <MenuItem key={option} value={option}>*/}
          {/*          <Grid container direction="row" alignItems="center">*/}
          {/*            <Typography variant="body1" sx={{mr: 2}}>*/}
          {/*              {(moment.tz.zone(option) as MomentZone).abbr(new Date().getTime())}*/}
          {/*            </Typography>*/}
          {/*            <Typography variant="caption">({option})</Typography>*/}
          {/*          </Grid>*/}
          {/*        </MenuItem>*/}
          {/*      ))}*/}
          {/*    </TextField>*/}
          {/*  }*/}
          {/*  label="Timezone"*/}
          {/*/>*/}

        </Grid>
      </Paper>

    </Grid>
  )
}