import Alert from '@mui/material/Alert';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Form, Formik } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { Link, useNavigate } from 'react-router-dom';
import { TextLoop } from 'react-text-loop-next';
import { object, string } from 'yup';
import 'yup-phone-lite';
import { doc, UpdateData, updateDoc, setDoc } from 'firebase/firestore';
import { User } from 'firebase/auth';
import { db } from '../../core/firebase';
import { useProfile } from '../../core/hooks';
import StripeService from '../../services/stripe-service';
import WHHeader from './WHHeader';
import Toolbar from '@mui/material/Toolbar';
import FloatingPointer from '../../widgets/material-extensions/FloatingPointer';

const options = [
  // 'real estate',
  'housekeeping',
  'climate control',
  'protection',
  'landscaping',
  'audio/video',
  'repairing',
  'appliances',
  'plumbing',
  'pest control',
  'trash removal'
];

const schema = object().shape({
  phone: (string() as any)
    .phone('US', 'Please enter a valid US phone')
    .nullable()
    .optional(),
  email: string()
    .label('Enter your email')
    .email('Enter a valid email')
    .when('phone', (phone: string | undefined, _schema: any) =>
      typeof phone !== 'string' ? _schema
        .required('Email is required if no phone is entered') : _schema.nullable().optional()
    )
});

export default function WHIntroScreen(props: { user: User }) {
  const {user} = props;
  const desktop = useMediaQuery('(min-width:600px)');
  const [data, loading] = useDocumentData(doc(db, 'wh-signups', user.uid));
  const profile = useProfile()
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<null | Error>(null);
  const navigate = useNavigate();
  const elRef = useRef<any>();

  const scrollToSubmit = () => {
    const el = document.getElementById('submit-form');
    if (el) {
      el.scrollIntoView();
    }
  };

  const handleSubmit = (values: any) => {
    if (submitting) {
      console.log('Already submitting')
      return;
    }
    setSubmitting(true);
    if (!user) {
      console.error('No user');
      return;
    }
    setError(null);
    const ref = doc(db, `wh-signups`, user.uid);
    setDoc(ref, values)
      .then(() => {
        console.log('Signed up', values);
        navigate('/j/plans', {state: values});
        setSubmitting(false);
      })
      .catch((err: Error) => {
        setSubmitting(false);
        setError(new Error(`Could not sign up at this time. (Error: ${err.message})`));
      });
  };

  useEffect(() => {
    if (profile && StripeService.customerId(profile) && process.env.REACT_APP_ENV === 'production') {
      console.log('Have customer id', StripeService.customerId(profile));
      const id = StripeService.customerId(profile);
      if (id) {
        navigate('/j/plans', {state: {customerId: id}});
      }
    } else if (data && process.env.REACT_APP_ENV === 'production') {
      console.error('@TODO remove this once we figure out how to make it faster (customer creation)');
      // console.log('Not waiting for customer id');
      const {email, phone} = data;
      if (email || phone) {
        navigate('/j/plans', {state: {email, phone}});
      }
    }
  }, [profile, data]);

  return (
    <Fragment>

      <WHHeader showUseCases={true}/>
      <Grid container direction="column" alignItems="center">
          <Grid container direction="row" justifyContent="center">
              <Grid item><Typography variant={desktop ? 'h2' : 'h3'} component="div"
                                     sx={{color: '#fff', textAlign: 'left'}}>
                  We handle&nbsp;
              </Typography></Grid>
              <Grid item><Typography variant={desktop ? 'h2' : 'h3'} component="div"
                                     sx={{color: '#fff', textAlign: 'left', width: desktop ? 100 : 85}}>
                  <TextLoop interval={1000}>
                    {options.map((option, index: number) => <span key={`option-${index}`}
                                                                  style={{width: desktop ? 100 : 85}}>{option}</span>)}
                  </TextLoop>
              </Typography></Grid>
          </Grid>
      </Grid>

      {error && <Alert severity="error" onClose={() => setError(null)}>{error.message}</Alert>}

      <FloatingPointer
        parentRef={elRef}
        sx={{
          position: 'fixed',
          bottom: 48,
          left: 'calc(50% - 12px)'
        }}
        iconStyles={{color: '#fff'}}
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          ...(data ? data : {
            phone: user.phoneNumber,
            email: user.email
          })
        }}
        validationSchema={schema}
      >
        {({handleChange, values, errors, handleBlur, touched, submitCount}) =>
          <Form style={{width: '100%'}}>
            <Grid
              container
              direction="column"
              wrap="nowrap"
              justifyContent="center"
              alignItems="center"
              sx={{
                padding: '0 24px',
                '& > :not(:last-child)': {
                  marginBottom: 4,
                }
              }}
            >
              <Typography variant="body1" sx={{color: '#fff', textAlign: 'center'}}>
                Sign up for the pilot, starting <b>$149/month</b>
              </Typography>

              <TextField
                id="phone"
                name="phone"
                label="Phone"
                variant="filled"
                value={values.phone}
                onChange={(e: React.ChangeEvent<any>) => {
                  console.log(e.target.value);
                  handleChange(e);
                }}
                onBlur={(e: React.FocusEvent) => {
                  handleBlur(e);
                  scrollToSubmit();
                }}
                error={(touched.phone || submitCount > 0) && Boolean(errors.phone)}
                helperText={(touched.phone || submitCount > 0) && errors.phone ? errors.phone : null}
                placeholder="Phone 206 444-4444"
                sx={{maxWidth: 350, width: '100%'}}
                inputProps={{
                  autoComplete: 'tel',
                  inputMode: 'tel',
                  type: 'tel'
                }}
                type="tel"
              />

              <Typography
                variant="body1"
                sx={{
                  textAlign: 'center',
                  width: '100px',
                  color: '#fff',
                  '&:after, &:before': {
                    backgroundColor: 'rgba(255,255,255,0.9)',
                    content: '""',
                    display: 'inline-block',
                    height: '1px',
                    position: 'relative',
                    verticalAlign: 'middle',
                    width: '50%'
                  },
                  '&:after': {
                    left: '0.5em',
                    marginRight: '-50%'
                  },
                  '&:before': {
                    right: '0.5em',
                    marginLeft: '-50%'
                  }
                }}
              >Or</Typography>

              <TextField
                onChange={handleChange}
                onBlur={(e: React.FocusEvent) => {
                  handleBlur(e);
                  scrollToSubmit();
                }}
                name="email"
                variant="filled"
                value={values.email}
                error={!!errors.email && submitCount > 0}
                helperText={errors.email && submitCount > 0 ? errors.email : ''}
                label={`Email`}
                sx={{maxWidth: 350, width: '100%'}}
                inputProps={{
                  autoComplete: 'email',
                  type: 'email'
                }}
                type="email"
              />

              <Button
                ref={elRef}
                id="submit-form"
                type="submit"
                variant="contained"
                color="primary"
                size="medium"
                startIcon={submitting ? <CircularProgress sx={{color: '#000'}} size={16}/> : null}
                sx={{
                  minWidth: 250,
                  marginTop: 4,
                  color: '#000',
                  // backgroundColor: '#00aeef',
                  backgroundColor: '#fff',
                  '&:hover, &:active': {
                    // backgroundColor: '#00aeef'
                    backgroundColor: '#fff'
                  }
                }}
              >
                Sign Up
              </Button>
            </Grid>
          </Form>
        }
      </Formik>

    </Fragment>
  );
}
