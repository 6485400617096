import { IProfile } from '@jumpstart/core';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { User } from 'firebase/auth';
import { doc } from 'firebase/firestore';
import { Formik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { object, string } from 'yup';
import { db } from '../../../../core/firebase';
import { useProfile } from '../../../../core/hooks';
import { StripePrice, StripeProduct } from '../../../../core/schemas';
import { COLORS, stripeTheme, stripeThemeFonts } from '../../../../core/theme';
import { getDomain } from '../../../../core/utils';
import StripeService from '../../../../services/stripe-service';
import LoadingBackdrop from '../../../../widgets/LoadingBackdrop';
import { IPaymentFormData, ISubscriptionSetupData } from '../forms';
import { PaymentForm } from '../forms/PaymentForm';
import { EnhancedStripeProduct } from '../home-security-products';
import SubscriptionEstimateDetails from '../SubscriptionEstimateDetails';
import SubscriptionInvoiceDetails, { ISubscriptionInvoiceData } from '../SubscriptionInvoiceDetails';
import LoadingCheckout from './LoadingCheckout';


const validationSchema = object({
  ccname: string()
    .label('Enter your name')
    .required('Field is required'),
});

export interface ICheckoutView extends ISubscriptionSetupData {
  user: User;
  desktop: boolean;

  cachedData: {
    product: StripeProduct | null;
    price: StripePrice | null;
    items: EnhancedStripeProduct[];
  }
}

export default function CheckoutView(props: ICheckoutView) {
  const {user, subscriptionId, clientSecret, desktop, cachedData} = props;
  const [data, dataLoading] = useDocumentData<{ email?: string; phone?: string }>(doc(db,
    'wh-signups',
    props.user.uid)
  );

  const [subscriptionData, setSubscriptionData] = useState<Partial<ISubscriptionInvoiceData>>({});
  const [loading, setLoading] = useState(false);
  const profile: IProfile | undefined = useProfile();
  const [messages, _setMessages] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async (values: IPaymentFormData) => {
    if (!stripe || !elements) {
      throw new Error('Missing elements + stripe');
    }
    setSubmitting(true);
    // const cardElement = elements.getElement(CardElement);
    // if (!cardElement) {
    //   setSubmitting(false);
    //   throw new Error('Failed');
    // }
    // Use card Element to tokenize payment details
    const returnUrl = `${getDomain()}/j/plans/success`;
    console.log('Return URL', returnUrl);
    const {error} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: returnUrl,
        payment_method_data: {
          /**
           * The customer's billing details. Details collected by Elements will override values passed here.
           * Billing fields that are omitted in the Payment Element via the `fields` option required.
           *
           * @docs https://stripe.com/docs/api/payment_intents/create#create_payment_intent-payment_method_data-billing_details
           */
          billing_details: {
            name: values.ccname
          }
        }
      },

    });
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message as any);
    } else {
      console.error(error);
      setMessage(`An unexpected error occurred. (${error.message})`);
    }
    // let {error, paymentIntent} = await stripe.confirmCardPayment(clientSecret, {
    //   payment_method: {
    //     card: cardElement,
    //     billing_details: {
    //       name: values.ccname
    //     }
    //   }
    // })
    //
    // if (error) {
    //   // show error and collect new card details.
    //   setMessage(error.message ? error.message : 'Unknown stripe error message');
    //   return;
    // }

    setSubmitting(false);
    // setPaymentIntent(paymentIntent);
  };

  const setMessage = (message: string) => {
    _setMessages(`${messages}\n\n${message}`);
  };

  useEffect(() => {
    if (subscriptionId) {
      StripeService.retrieveSubscription({subscriptionId})
        .then((result) => {
          console.log(result);
          setSubscriptionData(result);
        });
    }
  }, [subscriptionId]);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
      if (!paymentIntent) {
        return;
      }
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
        case 'requires_confirmation':
          // setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage(`Unhandled Error "${paymentIntent.status}"`);
          break;
      }
    });
  }, [stripe]);

  if (!profile) {
    return (<LoadingCheckout desktop={desktop}/>);
  }

  if (!stripe || !elements) {
    return (<LoadingCheckout desktop={desktop}/>);
  }

  if (!stripe) {
    return (<LoadingCheckout desktop={desktop}/>);
  }

  if (!data) {
    return (<LoadingCheckout desktop={desktop}/>);
  }

  // if (paymentIntent && paymentIntent.status === 'succeeded') {
  //   return (<Navigate to={`/j/account`}/>);
  // }

  return (
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          ccname: `${profile.firstName}${profile.lastName ? ' ' + profile.lastName : ''}`
        } as IPaymentFormData}
        validationSchema={validationSchema}
      >
        {(formikProps) =>

          <Fragment>
            <LoadingBackdrop open={submitting} text="Submitting"/>
            {/*<WHHeader showUseCases={false} showTitle={false} logo={'Logo.png'}/>*/}
            <Grid
              container
              direction={desktop ? 'row' : 'column'}
              wrap="nowrap"
              sx={{
                '& > :not(:last-child)': {
                  marginBottom: desktop ? 'unset' : '1.5rem'
                }
              }}
            >
              <Grid item xs={desktop ? 6 : 12}
                    sx={{backgroundColor: desktop ? COLORS.gray6 : 'inherit'}}>
                {messages &&
                    <Alert severity="info" variant="filled" onClose={() => _setMessages('')}>{messages}</Alert>}
                {(subscriptionData.subscription && subscriptionData.price && subscriptionData.product) ?
                    <SubscriptionInvoiceDetails
                      {...(subscriptionData as any)}
                      desktop={desktop}
                      loading={loading}
                    /> : <SubscriptionEstimateDetails desktop={desktop} loading={true} {...cachedData}/>
                }
              </Grid>

              <Grid
                item
                xs={desktop ? 6 : 12}
                sx={{
                  minHeight: '100vh',
                  backgroundColor: '#fff',
                  '&:before': desktop ? {
                    content: '" "',
                    boxShadow: '15px 0 30px 0 rgb(0 0 0 / 18%)',
                    height: '100%',
                    width: '50%',
                    background: '#fff',
                    position: 'fixed',
                    top: 0,
                    right: 0,
                    animationFillMode: 'both',
                    transformOrigin: 'right'
                  } : {}
                }}
              >
                <PaymentForm
                  stripe={stripe}
                  formikProps={formikProps}
                  desktop={desktop}
                  loading={loading}
                />
              </Grid>

            </Grid>

          </Fragment>
        }
      </Formik>
  );
}
