import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import React, { CSSProperties } from 'react';


function LogoNine() {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <g id="Layer_4" data-name="Layer 4">
        <polygon className="cls-1"
                 style={{fill: '#fff'}}
                 points="208.41 227.18 303.59 227.18 303.59 114.53 398.76 114.53 256 0.89 113.24 114.53 208.41 114.53 208.41 227.18"/>
        <polygon className="cls-1"
                 style={{fill: '#fff'}}
                 points="398.76 114.53 398.76 432.4 303.59 432.4 303.59 319.74 208.41 319.74 208.41 432.4 113.24 432.4 113.24 114.53 18.07 114.53 18.07 432.4 18.07 511.11 493.94 511.11 493.94 432.4 493.94 114.53 398.76 114.53"/>
      </g>
    </svg>
  );
}

export interface ILoading {
  hidden?: () => void;
  show: boolean;
  text?: string;
}

// background: (theme) => `linear-gradient(60deg, ${theme.palette.primary.main} 0%,${theme.palette.primary.dark} 100%)`,
//   color: '#fff'
export default function Loading(props: ILoading) {
  return (
    <Collapse in={props.show}>
      <div className="scout-loading hideable" style={{ position: 'fixed', top: 0 }}>
        <div className="logo logo-text">
          <LogoNine/>
        </div>
        <svg className="spinner" viewBox="25 25 50 50">
          <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth={2} strokeMiterlimit={10}/>
        </svg>
        {
          props.text
            && <Fade in={true}><Typography
              sx={{
                position: 'absolute',
                transform: 'translateY(160px)',
                color: '#FFFFFF'
              }}
              variant="body1"
            >{props.text}</Typography></Fade>
        }
      </div>
    </Collapse>
  );

}
