import { kingCountyZipcodes } from './utils/zipcodes/zips';

export interface IPlan {
  id: string;
  idTest: string;
  priceMonthlyId: string;
  priceMonthlyIdTest: string;
  priceYearlyId: string;
  priceYearlyIdTest: string;
  name: string;
  priceMonthly: string;
  priceYearly: string;
  details: {
    title: string;
    details: string;
  }[];
  popular: boolean;
}


export const PLANS: IPlan[] = [
  {
    id: 'prod_L1avpHFXVYcDL6',
    idTest: 'prod_L1auuWwsH31Qel',
    priceMonthlyId: 'price_1KUIOjGplLfnv6vWAOiPffWp',
    priceMonthlyIdTest: 'price_1KUIJbGplLfnv6vWH8gINzNa',
    priceYearlyId: 'price_1KUIPcGplLfnv6vWTBYHPaIk',
    priceYearlyIdTest: 'price_1KUILpGplLfnv6vWMQ79y0Ie',
    name: 'Standard Plan',
    priceMonthly: '$449/month',
    priceYearly: '$4,310/year',
    details: [
      {
        title: 'Dedicated house manager',
        details: 'Access to your own personal dedicated assistant, your point person for all your house needs and access to the WelcomeHome team.'
      },
      {
        title: 'Included weekly house cleaning',
        details: 'We will send a team of house cleaning experts each week to keep your home clean and up to date.'
      },
      {
        title: 'Included weekly landscaping',
        details: 'We will send a team of lawn and garden experts each week to keep your lawn, bushes, and grass beds up to date.'
      },
      {
        title: 'Access to WelcomeHome services',
        details: 'WelcomeHome in-houses the best technicians in the market for a wide variety of services. All employees that come to your home are certified, trained, and verified by our high quality standards. All employees are salaried at premium market rates.'
      },
      {
        title: 'Home service ledger',
        details: 'Log all service work onto your house into your own ledger. We use smart contract technology to build a system record on your home. Use your ledger to increase your resale value.'
      }
    ],
    popular: true
  },
  {
    id: 'prod_L12aJdJ7U4ZxCA',
    idTest: 'prod_L12KFLOJvDsuZ9',
    priceMonthlyId: 'price_1KL0UtGplLfnv6vWIvIW8Lvh',
    priceMonthlyIdTest: 'price_1KL0FPGplLfnv6vWcMc1Nsye',
    priceYearlyId: 'price_1KUIQcGplLfnv6vWde6Mhu8N',
    priceYearlyIdTest: 'price_1KUIN9GplLfnv6vWOilyZFhy',
    name: 'Base Plan',
    priceMonthly: '$149/month',
    priceYearly: '$1,430/year',
    details: [
      {
        title: 'Dedicated house manager',
        details: 'Access to your own personal dedicated assistant, your point person for all your home service-related needs with the WelcomeHome team.'
      },
      {
        title: 'Access to WelcomeHome services',
        details: 'WelcomeHome in-houses the best technicians in the market for a wide variety of services. All employees that come to your home are certified, trained, and verified by our high quality standards. All employees are salaried at premium market rates.'
      },
      {
        title: 'Home service ledger',
        details: 'Log all service work onto your house into your own ledger. We use smart contract technology to build a system record on your home. Use your ledger to increase your resale value.'
      }
    ],
    popular: false
  }
];

export interface IWHServiceLine {
  id: string;
  name: string;
  details: string;
  price: string;
}

export const SERVICELINES: IWHServiceLine[] = [
  {
    id: '123',
    name: 'Scheduling',
    details: 'Your dedicated house manager will schedule and coordinate WelcomeHome services on your behalf, but can also schedule dental, daycare, and vet appointments for you.',
    price: 'Included'
  },
  {
    id: '123',
    name: 'Project Management',
    details: 'For any projects going on in your house (e.g. remodeling) - your dedicated house manager can act as your own personal project manager to give you reminders and more.',
    price: 'Included'
  },
  {
    id: '123',
    name: 'Housekeeping',
    details: 'Recurring house keeping services, that can occur on a weekly basis or paid for a one time fee',
    price: '$149/mo'
  },
  {
    id: '123',
    name: 'Landscaping',
    price: '$149/mo',
    details: 'Recurring landscaping and gardening services, that can occur on a weekly basis or paid for a one time fee'
  },
  {
    id: '123',
    name: 'Protection',
    price: '$999-2999',
    details: 'One time service to install electronic security systems. Varies in price based on service needs.'
  },
  {
    id: '123',
    name: 'Climate Control',
    price: '$999-2999',
    details: 'Heating and HVAC installation and repairs. Varies in price based on service needs.'
  },
  {
    id: '123',
    name: 'Audio/Video',
    price: '$499-2499',
    details: 'Audio and video installation and repairs. Varies in price based on service needs.'
  },
  {
    id: '123',
    name: 'Appliance Installations',
    price: '$99-599',
    details: 'Kitchen and other home appliance installations'
  },
  {
    id: '123',
    name: 'Appliance Delivery',
    price: '$49-399',
    details: 'Kitchen and other home appliance delivery management'
  },
  {
    id: '123',
    name: 'Plumbing',
    price: '$149-499',
    details: ''
  },
  {
    id: '123',
    name: 'Pest Control',
    price: '$149-499',
    details: ''
  },
  {
    id: '123',
    name: 'Trash Removal',
    price: '$149/mo',
    details: ''
  },
  {
    id: '123',
    name: 'Repairs',
    price: '(varying price)',
    details: 'Appliance repairs for kitchen appliances, garage doors, fireplaces, or even outdoor grills. Repair costs vary on the appliance and severity of the damage.'
  }
];

export const whitelistedZipcodes: string[] = [
  ...kingCountyZipcodes
];

