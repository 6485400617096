import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { User } from 'firebase/auth';
import React, { useState } from 'react';
import { StripePrice, StripeProduct } from '../../../../core/schemas';
import { COLORS } from '../../../../core/theme';
import { EnhancedStripeProduct } from '../home-security-products';
import SubscriptionEstimateDetails from '../SubscriptionEstimateDetails';


export interface ICheckoutViewPrepping {
  product: StripeProduct | null;
  price: StripePrice | null;
  items: EnhancedStripeProduct[];
  user: User;
  desktop: boolean;
}

export default function CheckoutViewPrepping(props: ICheckoutViewPrepping) {
  const {desktop} = props;
  const [loading, setLoading] = useState(false);

  // if (paymentIntent && paymentIntent.status === 'succeeded') {
  //   return (<Navigate to={`/j/account`}/>);
  // }

  return (
    <Grid
      container
      direction={desktop ? 'row' : 'column'}
      wrap="nowrap"
      sx={{
        '& > :not(:last-child)': {
          marginBottom: desktop ? 'unset' : '1.5rem'
        }
      }}
    >
      <Grid item xs={desktop ? 6 : 12}
            sx={{backgroundColor: desktop ? COLORS.gray6 : 'inherit'}}>
        <SubscriptionEstimateDetails
          {...props}
          desktop={desktop}
          loading={loading}
        />
      </Grid>

      <Grid
        item
        xs={desktop ? 6 : 12}
        sx={{
          minHeight: '100vh',
          backgroundColor: '#fff',
          '&:before': desktop ? {
            content: '" "',
            boxShadow: '15px 0 30px 0 rgb(0 0 0 / 18%)',
            height: '100%',
            width: '50%',
            background: '#fff',
            position: 'fixed',
            top: 0,
            right: 0,
            animationFillMode: 'both',
            transformOrigin: 'right'
          } : {}
        }}
      >
        <Grid item sx={{zIndex: 2, position: 'relative'}}>
          <Grid container direction="column" alignItems="center" justifyContent={desktop ? 'center' : 'unset'}
                sx={{flexGrow: 1, minHeight: '100vh'}}>
            <CircularProgress size={64}/>
            <Typography variant="caption" sx={{mt: 4}}>Creating Order</Typography>
          </Grid>
        </Grid>

      </Grid>

    </Grid>
  );
}
