import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { FormikErrors, FormikProps } from 'formik/dist/types';
import React, { Fragment } from 'react';
import InfoTip from '../../../../widgets/material-extensions/InfoTip';
import { EnhancedStripeProduct, HOME_SECURITY_PRODUCTS } from '../home-security-products';
import ProductCardSelect from './ProductCardSelect';
import { IHome, ISubscribeFormData } from './schemas';
import { inputStyles, labelStyles } from './styles';

export interface IHomeLockSubForm extends FormikProps<ISubscribeFormData> {
  homeValues: IHome;
  homeErrors: FormikErrors<IHome>;
  fieldPath: string;
}

export default function HomeLockSubForm(props: IHomeLockSubForm) {
  const {
    homeValues,
    homeErrors,
    submitCount,
    handleChange,
    setFieldValue,
    fieldPath
  } = props;

  // @TODO use a provider/context
  const handleSelectProduct = (option: EnhancedStripeProduct | null) => {
    if (option) {
      setFieldValue(`${fieldPath}.lockProductId`, option.id);
    } else {
      setFieldValue(`${fieldPath}.lockProductId`, undefined);
    }
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      sx={{
        padding: '24px 0',
        '& > :not(:last-child)': {
          marginBottom: 4
        }
      }}
    >

      <Grid
        container
        direction="column"
        alignItems="center"
        sx={{
          '& > :not(:last-child)': {
            marginBottom: 1
          }
        }}
      >
        <Typography variant="body1">Does this home have a digital lock?</Typography>
        <ToggleButtonGroup
          color="primary"
          fullWidth
          size="small"
          value={homeValues.hasDigitalLock}
          sx={{
            maxWidth: 300
          }}
          exclusive
          onChange={(event, newValue) => setFieldValue(`${fieldPath}.hasDigitalLock`, newValue)}
        >
          <ToggleButton value={true}>Yes</ToggleButton>
          <ToggleButton value={false}>No</ToggleButton>
        </ToggleButtonGroup>

      </Grid>
      {homeErrors.hasDigitalLock && submitCount > 0 && <Typography variant="body2" color="error">{homeErrors.hasDigitalLock}</Typography>}

      {typeof homeValues.hasDigitalLock === 'boolean' && <Fragment>
        {homeValues.hasDigitalLock ? (
          <Fragment>
            <FormControlLabel
              sx={labelStyles}
              control={
                <TextField
                  onChange={handleChange}
                  id={`${fieldPath}.accessDetails`}
                  name={`${fieldPath}.accessDetails`}
                  variant="filled"
                  value={homeValues.accessDetails}
                  error={!!homeErrors.accessDetails && submitCount > 0}
                  placeholder="Door code 3215"
                  helperText={homeErrors.accessDetails && submitCount > 0 ? homeErrors.accessDetails : 'Information to assist our staff from entry for jobs'}
                  sx={inputStyles}
                  multiline
                  maxRows={3}
                />
              }
              // label="Access instructions"
              label={
                <InfoTip title="Our house techs will need to be able to access your home to complete jobs, you will be given a heads up when they arrive and can adjust times as needed.">
                  <Typography variant="body1" sx={{marginRight: 1}}>Access instructions</Typography>
                </InfoTip>
              }
            />
          </Fragment>
        ) : (
          <Fragment>
            <InfoTip title="Needed for our staff to gain entry and complete their tasks">
              <Typography variant="body1">Home is required to have a digital lock</Typography>
            </InfoTip>
            <ProductCardSelect
              selected={homeValues.lockProductId}
              options={process.env.REACT_APP_ENV === 'production' ? HOME_SECURITY_PRODUCTS.live : HOME_SECURITY_PRODUCTS.test}
              onSelect={handleSelectProduct}
            />
            <Typography variant="body2" color="textSecondary">Select a digital lock, we will handle
              installation.</Typography>
          </Fragment>
        )
        }
      </Fragment>}
      {homeErrors.accessDetails && submitCount > 0 && <Typography variant="body2" color="error">{homeErrors.accessDetails}</Typography>}
      {homeErrors.lockProductId && submitCount > 0 && <Typography variant="body2" color="error">{homeErrors.lockProductId}</Typography>}
    </Grid>
  );
}