import Collapse from '@mui/material/Collapse';
import FormControlLabel, { FormControlLabelProps as MuiFormControlLabelProps } from '@mui/material/FormControlLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import TextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import React, { Fragment, useRef, useState } from 'react';
import usePlacesAutocomplete, { getDetails, Suggestion } from 'use-places-autocomplete';

export interface IPlacesSearch {
  FormControlLabelProps?: Omit<MuiFormControlLabelProps, 'control'>;
  TextFieldProps?: MuiTextFieldProps;
  useFormControl?: boolean;

  onChange(suggestion: Suggestion): void;
}

// King County
const defaultBounds = {
  north: 47.895568,
  south: 47.377924,
  east: -121.997483,
  west: -122.462088
};

export default function PlacesSearch(props: IPlacesSearch) {
  const {onChange, TextFieldProps, FormControlLabelProps, useFormControl} = {
    TextFieldProps: {},
    FormControlLabelProps: {label: 'Label'}, ...props
  };
  const ref = useRef<any>();
  const [focus, setFocus] = useState(false);
  const {
    ready,
    value,
    suggestions: {status, data},
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      bounds: defaultBounds
      // types: ['establishment']
      // location: new google.maps.LatLng({lat: props.position.coords.latitude, lng: props.position.coords.longitude})
    },
    debounce: 300
  });


  const handleInput = (e: any) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleDetails = (placeId: string): Promise<google.maps.places.PlaceResult> => {
    const parameter = {
      // Use the "place_id" of suggestion from the dropdown (object), here just taking first suggestion for brevity
      placeId
      // Specify the return data that you want (optional)
      // fields: ['name', 'rating', 'types']
    };
    return getDetails(parameter)
      .then((details: google.maps.places.PlaceResult | string) => {
        if (typeof details === 'string') {
          console.error('What to do with this?', details);
          return {name: 'unknown'};
        } else {
          return details;
        }
      })
      .catch((error: Error) => {
        console.error('Error: ', error);
        return {name: 'unknown'};
      });
  };

  const handleSelect =
    // @ts-ignore
    (suggestion: Suggestion) =>
      () => {
        const {description} = suggestion;
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(description, false);
        onChange(suggestion);
        clearSuggestions();
      };

  const renderSuggestions = () =>
    data.map((suggestion: Suggestion) => {
      const {
        place_id,
        structured_formatting: {main_text, secondary_text}
      } = suggestion;

      return (
        <ListItem key={place_id} onClick={handleSelect(suggestion)} button sx={{maxWidth: 350, width: 'calc(100vw - 96px)'}}>
          <ListItemText primary={main_text} secondary={secondary_text}/>
        </ListItem>
      );
    });

  return (
    // <ClickAwayListener onClickAway={clearSuggestions}>
      <Fragment>
      {useFormControl ?
        <FormControlLabel
          {...FormControlLabelProps}
          control={
            <TextField
              {...TextFieldProps}
              value={value}
              onChange={handleInput}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
            />
          }
        />
        : <TextField
          {...TextFieldProps}
          value={value}
          onChange={handleInput}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />}
      {/* We can use the "status" to decide whether we should display the dropdown or not */}

      <Collapse in={focus && data.length > 0}>
        <Paper sx={{width: '100%'}}>
          <List sx={{width: '100%'}}>{renderSuggestions()}</List>
        </Paper>
      </Collapse>
      </Fragment>

    // </ClickAwayListener>
  );
};
