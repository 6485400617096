import { createTheme } from '@mui/material/styles';
import { Appearance, CssFontSource, CustomFontSource } from '@stripe/stripe-js/types/stripe-js/elements-group';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    boxed: boolean;
  }

  interface ButtonClasses {
    boxed: string;
  }
}

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    special: boolean;
  }

  // interface PaperClasses {
  //   boxed: string;
  // }
}

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    special: boolean;
  }

  // interface PaperClasses {
  //   boxed: string;
  // }
}
// declare module '@mui/material/styles/components' {
// }

export const COLORS = {
  lightBlack: '#0E0E2C',
  black: '#000000',
  white: '#FFFFFF',
  gray1: '#202632',
  gray2: '#444B59',
  gray3: '#8E8E9B',
  gray4: '#B5B7C4',
  gray5: '#D7D8DF',
  gray6: '#F8F8F9',
  gray7: '#EFF0F6',
  orange: '#DD8B66',
  gold: '#F3BD50',
  yellow: '#E8B651',
  darkBlue: '#393957',
  purple: '#47478E',
  blue: '#4C7DFE',
  blueGray: '#6E7191',
  lightGreen: '#F2FFFB',
  green: '#00966D',
  darkGreen: '#3E7A62',
  lightRed: '#FFF3F8',
  red: '#D05D5C',
  darkRed: '#C30052'
};

export const stripeThemeFonts: Array<CssFontSource | CustomFontSource> = [{cssSrc: 'https://fonts.googleapis.com/css2?family=Inter&display=swap'}];
export const stripeTheme: Appearance = {
  theme: 'flat',
  variables: {
    fontFamily: 'Inter',
    borderRadius: '6px',
    colorPrimary: COLORS.black,
    colorPrimaryText: COLORS.black,
    colorBackground: COLORS.gray6,
    colorBackgroundText: COLORS.red,
    colorText: COLORS.darkBlue,
    colorSuccess: COLORS.green,
    colorSuccessText: COLORS.green,
    colorDanger: COLORS.red,
    colorDangerText: COLORS.red,
    colorWarning: COLORS.orange,
    colorWarningText: COLORS.orange,

    // Text variations
    colorTextSecondary: COLORS.blue,
    colorTextPlaceholder: COLORS.gray5,


    fontSizeBase: '16px',
    fontSizeSm: '16px',
    fontSizeXs: '16px',
    fontSize2Xs: '16px',
    fontSize3Xs: '16px',
    fontSizeLg: '16px',
    fontSizeXl: '16px',

    focusOutline: 'unset',
    focusBoxShadow: 'unset'
  }

}

const defaultTheme = createTheme({});

export const MATERIAL_THEME = createTheme({
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer'
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 8,
          borderRadius: '10px',
          '&::before': {
            opacity: '0.1'
          }
        },
        bar: {
          borderRadius: '10px'
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0
      },
      variants: [
        {
          props: { variant: 'special' as any} as any,
          style: {
            borderRadius: '10px',
            boxShadow: '0px 8px 40px rgba(71, 71, 142, 0.1)'
          }
        }
      ]
    },
    MuiPopover: {
      defaultProps: {
        PaperProps: {
          variant: 'special' as any
        }
      }
    },
    MuiList: {
      defaultProps: {
        disablePadding: true
      },
      styleOverrides: {
        root: {
          // backgroundColor: COLORS.gray6,
          '& > :not(:last-child)': {
            marginBottom: '6px'
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.white,
          borderRadius: '0'
        },
        // selected: {
        //   color: COLORS.white,
        //   backgroundColor: COLORS.darkBlue
        // },
        disabled: {}
      }
    },
    MuiListItem: {
      defaultProps: {
        disablePadding: true
      },
      styleOverrides: {
        root: {
          backgroundColor: COLORS.white,
          borderRadius: '6px'
        },
        // selected: {
        //   color: COLORS.white,
        //   backgroundColor: COLORS.darkBlue
        // },
        disabled: {}
      },
      variants: [
        // {
        //   props: { variant: 'text' },
        //   style: {
        //     textTransform: 'none',
        //     border: `2px dashed grey${blue[500]}`,
        //   },
        // },
      ]
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: COLORS.blueGray,
          letterSpacing: '0.25px',
          fontStyle: 'normal',
          fontWeight: 500
        }
      }
    },
    MuiChip: {
      variants: [
        {
          props: { variant: 'filled', color: 'default' },
          style: {
            backgroundColor: `${COLORS.black} !important`,
            color: COLORS.white
          }
        },
        {
          props: { variant: 'filled', color: 'primary' },
          style: {
            backgroundColor: `${COLORS.purple} !important`,
            color: COLORS.white
          }
        }
      ]
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: '16px 24px'
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined'
        // SelectProps: {
        //   MenuProps: {
        //     anchorOrigin: {
        //       vertical: 'bottom',
        //       horizontal: 'center'
        //     }
        //   }
        // }
      },
      styleOverrides: {}
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true
      },
      styleOverrides: {
        root: {
          background: COLORS.gray6,
          borderRadius: '6px',
          '&.Mui-focused': {
            background: COLORS.gray6,
            borderRadius: '6px'
          },
          '&:hover': {
            background: COLORS.gray6
          }
        },
        focused: {
          background: COLORS.gray6
        },
        input: {
          color: COLORS.blue
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          maxWidth: 350,
          textTransform: 'none',
          fontSize: 16,
          color: COLORS.gray2,
          lineHeight: '150%',
          fontWeight: 'normal',
          fontStyle: 'normal'
        },
        notchedOutline: {
          '> legend': {
            // @TODO check if this is a good idea
            display: 'none' // We want the label to be inside the box, hide the legend
          },
          top: 0,
          border: `2px solid ${COLORS.gray2}`,
          borderRadius: '16px'
        },
        focused: {
          border: `2px solid ${COLORS.gray1}`
        },
        input: {
          padding: '20px 0px 14px 14px'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: COLORS.gray3
        },


        outlined: {
          '&.MuiInputLabel-root': {
            transform: 'translate(14px, 17px) scale(1)',
            fontWeight: 400,
            letterSpacing: '0.75px'
          },
          '&.MuiInputLabel-shrink': {
            backgroundColor: 'transparent',
            transform: 'translate(14px, 4px) scale(0.7)',
            // color: COLORS.blueGray,
            fontWeight: 500,
            letterSpacing: '0.75px'
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        inputMultiline: {
          padding: '0 !important'
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
          transform: 'scale(0.95)',
          '&:hover': {
            transform: 'scale(1)'
          },
          '&:active': {
            transform: 'scale(0.9)'
          }
        }
      }
    },
    MuiButton: {
      defaultProps: {
        size: 'large',
        variant: 'text'
      },
      styleOverrides: {
        disabled: {
          backgroundColor: COLORS.gray6,
          color: COLORS.gray4,
          border: `1px solid #${COLORS.gray5}`
        },
        root: {
          transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
          transform: 'scale(0.95)',
          '&:hover': {
            transform: 'scale(1)'
          },
          '&:active': {
            transform: 'scale(0.9)'
          },
          maxWidth: 350,
          textTransform: 'none',
          fontSize: 16,
          lineHeight: '150%',
          fontWeight: 'normal',
          fontStyle: 'normal'
        },
        outlined: {
          borderRadius: '50px',
          boxShadow: defaultTheme.shadows[0],
          '&:active': {
            boxShadow: defaultTheme.shadows[0]
          }
        },
        contained: {
          borderRadius: '50px',
          boxShadow: defaultTheme.shadows[0],
          '&:active': {
            boxShadow: defaultTheme.shadows[0]
          }
        },
        boxed: {
          borderRadius: '2px'
        }
      },
      variants: [
        {
          props: { size: 'large' },
          style: {
            width: '100%',
            fontSize: '16px',
            lineHeight: '24px',
            padding: '14px 28px'
          }
        },
        {
          props: { size: 'medium' },
          style: {
            fontSize: '16px',
            lineHeight: '24px',
            padding: '10px 20px'
          }
        },
        {
          props: { size: 'small' },
          style: {
            fontSize: '14px',
            lineHeight: '20px',
            padding: '6px 12px'
          }
        },
        {
          props: { variant: 'boxed', color: 'primary' },
          style: {
            backgroundColor: COLORS.blue,
            color: COLORS.white,
            boxShadow: defaultTheme.shadows[0],
            '&:active': {
              backgroundColor: COLORS.darkBlue,
              boxShadow: defaultTheme.shadows[0]
            },
            '&:hover': {
              backgroundColor: COLORS.darkBlue
            }
          }
        },
        {
          props: { variant: 'boxed', color: 'secondary' },
          style: {
            backgroundColor: COLORS.gold,
            boxShadow: defaultTheme.shadows[0],
            '&:active': {
              backgroundColor: COLORS.yellow,
              boxShadow: defaultTheme.shadows[0]
            },
            '&:hover': {
              backgroundColor: COLORS.yellow
            }
          }
        },
        {
          props: { variant: 'boxed', color: 'success' },
          style: {
            backgroundColor: COLORS.green,
            color: COLORS.white,
            boxShadow: defaultTheme.shadows[0],
            '&:active': {
              backgroundColor: COLORS.darkGreen,
              boxShadow: defaultTheme.shadows[0]
            },
            '&:hover': {
              backgroundColor: COLORS.darkGreen
            }
          }
        },
        {
          props: { variant: 'boxed', color: 'error' },
          style: {
            backgroundColor: COLORS.red,
            color: COLORS.white,
            boxShadow: defaultTheme.shadows[0],
            '&:active': {
              backgroundColor: COLORS.darkRed,
              boxShadow: defaultTheme.shadows[0]
            },
            '&:hover': {
              backgroundColor: COLORS.darkRed
            }
          }
        },
        {
          props: { variant: 'boxed', color: 'info' },
          style: {
            backgroundColor: COLORS.gray2,
            color: COLORS.white,
            boxShadow: defaultTheme.shadows[0],
            '&:active': {
              backgroundColor: COLORS.gray3,
              boxShadow: defaultTheme.shadows[0]
            },
            '&:hover': {
              backgroundColor: COLORS.gray3
            }
          }
        },
        {
          props: { variant: 'boxed', color: 'warning' },
          style: {
            backgroundColor: COLORS.orange,
            color: COLORS.white,
            boxShadow: defaultTheme.shadows[0],
            '&:active': {
              backgroundColor: COLORS.orange,
              boxShadow: defaultTheme.shadows[0]
            },
            '&:hover': {
              backgroundColor: COLORS.orange
            }
          }
        }
      ]
    },
    MuiFormControlLabel: {
      defaultProps: {
        labelPlacement: 'top',
      },
      styleOverrides: {
        root: {
          width: '100%',
          marginLeft: 0,
          marginRight: 0,
          alignItems: 'flex-start'
        }
      }
    },
    MuiTooltip: {
      defaultProps: {
        arrow: false,
        enterTouchDelay: 100,
        leaveTouchDelay: 2000
      }
    }
  },
  spacing: 4,
  palette: {
    primary: {
      light: COLORS.purple,
      main: COLORS.blue,
      dark: COLORS.darkBlue,
      contrastText: COLORS.white
    },
    info: {
      light: COLORS.darkBlue,
      main: COLORS.gray1,
      dark: COLORS.black,
      contrastText: COLORS.white
    },
    secondary: {
      light: COLORS.gold,
      main: COLORS.gold,
      dark: COLORS.yellow,
      contrastText: COLORS.gray1
    },
    error: {
      light: COLORS.lightRed,
      main: COLORS.red,
      dark: COLORS.darkRed,
      contrastText: COLORS.white
    },
    success: {
      light: COLORS.lightGreen,
      main: COLORS.green,
      dark: COLORS.darkGreen,
      contrastText: COLORS.white
    },
    warning: {
      light: COLORS.orange,
      main: COLORS.orange,
      dark: COLORS.orange,
      contrastText: COLORS.white
    },
    text: {
      primary: COLORS.darkBlue,
      secondary: COLORS.gray3,
      disabled: COLORS.gray4
    }
  },
  typography: {
    fontFamily: [
      'Inter',
      '"Inter"',
      'arial'
    ].join(','),
    h1: {
      fontFamily: 'Inter',
      lineHeight: '41px',
      fontSize: '34px',
      fontWeight: 'bold',
      fontStyle: 'normal',
      letterSpacing: '-0.02em',
      color: COLORS.lightBlack
    },
    h2: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '22px',
      lineHeight: '32px',
      letterSpacing: '-0.02em',
      color: COLORS.lightBlack
    },
    h3: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '18px',
      lineHeight: '28px',
      color: COLORS.lightBlack
    },
    h4: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '18px',
      lineHeight: '28px',
      color: COLORS.lightBlack
    },
    h5: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '160%'
    },
    h6: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: '160%'
    },
    subtitle1: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '140%'
    },
    subtitle2: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '140%'
    },
    body1: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '160%'
    },
    body2: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '180%'
    },
    button: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      textTransform: 'none'
    },
    caption: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '13px',
      letterSpacing: '0.066px',
      color: COLORS.gray3
    },
    overline: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '160%'
    }
  }

});

