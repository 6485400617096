import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { IWHServiceLine, SERVICELINES } from '../core/config';

export interface IServicesContent {
  promoting: boolean;
  onSelect?(line: IWHServiceLine): void;
}
export default function ServicesList(props: IServicesContent) {
  const { promoting, onSelect} = props;
  return (
    <Grid container direction="column">
      {SERVICELINES.map((line: IWHServiceLine, index: number) =>

        <Accordion key={`service-line-${index}`}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls={`panel1a-content-${index}`}
            id={`panel1a-header-${index}`}
          >
            <Typography sx={{ flexGrow: 1 }}>
              {line.name}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>{line.price}</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              backgroundColor: '#f9f9f9'
            }}
          >
            <Typography>
              {line.details}
            </Typography>
            {onSelect && <AccordionActions>
                <Button color="primary" variant="contained" size="small" onClick={() => onSelect(line)}>Select</Button>
            </AccordionActions>}
          </AccordionDetails>
        </Accordion>
      )}
      {promoting && <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
        >
          <Typography>
            And More
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: '#f9f9f9'
          }}
        >
          <Typography>
            Anything else you may need, ask your house manager and they will assist you!
          </Typography>
        </AccordionDetails>
      </Accordion>}
    </Grid>
  );
}
