import { User } from 'firebase/auth';
import React, {lazy} from 'react';
import { NavigateProps } from 'react-router';
import { Navigate, Outlet } from 'react-router-dom';

import WHCheckoutScreen from './screens/join/subscribe/WHCheckoutScreen';
import WHSubscribeWrapper from './screens/join/subscribe/WHSubscribeWrapper';
import WHSuccessScreen from './screens/join/subscribe/WHSuccessScreen';
import WHIntroScreen from './screens/join/WHIntroScreen';
import WHOutlet from './screens/join/WHOutlet';
import WHPlansScreen from './screens/join/WHPlansScreen';
import WHSubscribeSetupScreen from './screens/join/subscribe/WHSubscribeSetupScreen';

const ServiceRequestScreen = lazy(() => import('./screens/services/ServiceRequestScreen'));
const ServicesListScreen = lazy(() => import('./screens/services/ServicesListScreen'));
const ServicesOutlet = lazy(() => import('./screens/services/ServicesOutlet'));
const SignInScreen = lazy(() => import('./screens/signin/SignInScreen'));
const ThemePage = lazy(() => import('./screens/theme/ThemePage'));
const AccountScreen = lazy(() => import('./screens/account/AccountScreen'));


function WrapNavigate(props: NavigateProps & { id: string }) {
  if (process.env.REACT_APP_ENV === 'development') {
    console.log(props.id, 'Redirecting to...', props.to);
  }
  return (
    <Navigate {...props}/>
  );
}

const routes = (user: User, isAdmin: boolean) => {
  return [
    {
      path: '/',
      element: <WrapNavigate to={'/j'} id={'/'}/>
    },
    {
      path: '/j',
      element: <WHOutlet user={user}/>,
      children: [
        {path: 'i', element: <WHIntroScreen user={user}/>},
        {
          path: 'plans', element: <Outlet/>, children: [
            {path: 'select', element: <WHPlansScreen user={user}/>},
            {path: 'success', element: <WHSuccessScreen user={user}/>},
            {
              path: 'setup', element: <Outlet/>, children: [
                {
                  path: ':product', element: <Outlet/>, children: [
                    {path: ':price', element: <WHSubscribeSetupScreen user={user}/>}
                  ]
                }
              ]
            },
            {
              path: 'checkout', element: <WHSubscribeWrapper/>, children: [
                {path: '', element: <WHCheckoutScreen user={user}/>}
              ]
            },
            {path: '', element: <WrapNavigate id="/j/plans/*" to="/j/plans/select"/>},
          ]
        },
        {path: '', element: <WrapNavigate id="/*" to="/j/i"/>}
      ]
    },
    {
      path: '/services',
      element: <ServicesOutlet user={user}/>,
      children: [
        {
          path: 'request', element: <Outlet/>, children: [
            {path: ':id', element: <ServiceRequestScreen/>}
          ]
        },
        {path: '', element: <ServicesListScreen user={user}/>}
      ]
    },
    {
      path: '/account',
      element: user.isAnonymous ? <WrapNavigate id="/*" to="/signin?redirect=/account"/> : <AccountScreen user={user}/>
    },
    {path: '/signin', element: <SignInScreen defaultRedirect={'/account'}/>},
    {
      path: '/theme',
      element: isAdmin ? <ThemePage/> : <WrapNavigate id="/theme" to={`/signin?redirect=/theme`}/>
    },
    {
      path: '',
      element: <WrapNavigate id="/*" to="/j"/>
    },
  ];
};

export default routes;
