import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { StripePrice, StripeProduct } from '../../../../core/schemas';
import { COLORS } from '../../../../core/theme';
import { IMAGE_PLACEHOLDER } from '../../../../core/utils';
import { discountPriceObj, EnhancedStripeProduct } from '../home-security-products';

export interface IOrderEstimate {
  desktop: boolean;
  product: StripeProduct;
  price: StripePrice;
  items: EnhancedStripeProduct[];
}

export default function OrderEstimate(props: IOrderEstimate) {
  const {desktop, items, price, product } = props;

  const resolveImage = () => {
    if (product.images.length > 0) {
      return product.images[0]
    } else {
      return IMAGE_PLACEHOLDER;
    }
  }

  const image = resolveImage();

  const itemsCost = () => items.reduce((accumulator: number, item: EnhancedStripeProduct) => {
    const price = discountPriceObj(item);
    if (price && typeof price.unit_amount === 'number') {
      accumulator += price.unit_amount;
    }
    return accumulator;
  }, 0)


  const subtotal = () => (typeof price.unit_amount === 'number' ? price.unit_amount : 0) + itemsCost();

  return (
    <List
      sx={{width: '100%', maxWidth: desktop ? 360 : 'unset', backgroundColor: 'transparent' }}
      aria-label="contacts"
    >
      <ListItem disablePadding={false} sx={{backgroundColor: 'transparent'}}>
        <ListItemIcon>
          <Avatar variant="rounded" src={image} sx={{width: 42, height: 42}}/>
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{component: 'div'}}
          primary={
            <Grid container direction="row" justifyContent="space-between">
              <Typography variant="subtitle2">{product.name}</Typography>
              {typeof price.unit_amount === 'number' &&
                  <Typography variant="subtitle2">${(price.unit_amount / 100).toFixed(2)}</Typography>}
            </Grid>
          }
          secondary={typeof price.recurring === 'object' ? `Billed ${price.recurring?.interval}ly` : ''}
          secondaryTypographyProps={{variant: 'caption'}}
        />
      </ListItem>
      {(items
        .map((item) => {return {price: discountPriceObj(item), product: item}})
        .filter((item => !!item.price)) as any[])
        .map(({price, product}) => <ListItem key={price.id} disablePadding={false} sx={{backgroundColor: 'transparent'}}>
        <ListItemText
          inset
          primaryTypographyProps={{component: 'div'}}
          primary={
            <Grid container direction="row" justifyContent="space-between">
              <Typography variant="subtitle2" sx={{
                width: 200,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}>{product.name}</Typography>
              {typeof price.unit_amount === 'number' &&
                  <Typography variant="subtitle2">${(price.unit_amount / 100).toFixed(2)}</Typography>}
            </Grid>
          }
        />
      </ListItem>)}
      <ListItem disablePadding={false} sx={{backgroundColor: 'transparent'}}>
        <ListItemText
          inset
          primary={
            <Grid container direction="row" justifyContent="space-between">
              <Typography variant="subtitle2">Subtotal</Typography>
              <Typography variant="subtitle2">${(subtotal() / 100).toFixed(2)}</Typography>
            </Grid>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li"/>
      <ListItem disablePadding={false} sx={{backgroundColor: 'transparent'}}>
        <ListItemText
          inset
          primary={
            <Grid container direction="row" justifyContent="space-between" alignItems="center" wrap="nowrap">
              <Grid item>

                <Grid container direction="row" alignItems="center">
                  <Typography variant="subtitle2" color="text.secondary" sx={{marginRight: 1}}>Tax</Typography>
                  <Tooltip title="Tax is determined by your billing information">
                    <InfoOutlinedIcon sx={{color: COLORS.gray3, fontSize: 14}} fontSize="small"/>
                  </Tooltip>
                </Grid>
              </Grid>
              <Typography variant="caption" color="text.secondary">Enter address to calculate</Typography>
            </Grid>
          }
        />
      </ListItem>
      {desktop && <Divider variant="inset" component="li"/>}
    </List>
  )
}