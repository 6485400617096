// To match stripe styles
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { PaymentElement } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import { Form } from 'formik';
import { FormikProps } from 'formik/dist/types';
import React from 'react';
import { IPaymentFormData } from './schemas';
import { inputStyles, labelStyles } from './styles';


export interface IPaymentForm {
  stripe: Stripe | undefined;
  desktop: boolean;
  formikProps: FormikProps<IPaymentFormData>;
  loading?: boolean;
}

export function PaymentForm(props: IPaymentForm) {
  const {stripe, formikProps, desktop, loading } = props;

  return (
    <Form style={{width: '100%', zIndex: 2, position: 'relative'}}>


      <Grid
        container
        direction="column"
        sx={{
          padding: '48px 24px',
          '& > :not(:last-child)': {
            marginBottom: 16
          }
        }}
        alignItems="center"
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          sx={{
            '& > :not(:last-child)': {
              marginBottom: 1
            }
          }}
        >
          {desktop &&
              <Typography variant="h3" sx={{textAlign: 'left', width: '100%', maxWidth: 450}}>Pay With Card</Typography>}

          <Typography variant="subtitle2" color="text.secondary"
                      sx={{textAlign: 'left', width: '100%', maxWidth: 450}}>Card Information</Typography>

          {loading
            ? <Skeleton width="100%" height={538} variant="rectangular"/>
            : <Paper
              elevation={1}
              sx={{
                width: '100%',
                maxWidth: 450,
                '& > *': {
                  padding: '48px 24px',
                }
              }}
            >
              <Grid
                container
                direction="column"
                alignItems="center"
                sx={{
                  '& > :not(:last-child)': {
                    marginBottom: 3
                  },
                  '& > *': {
                    maxWidth: 350,
                    width: '100%'
                  }
                }}
              >

                <FormControlLabel
                  sx={labelStyles}
                  control={
                    <TextField
                      onChange={formikProps.handleChange}
                      id="ccname"
                      name="ccname"
                      variant="filled"
                      value={formikProps.values.ccname}
                      placeholder={`Name On Card`}
                      error={!!formikProps.errors.ccname && formikProps.submitCount > 0}
                      helperText={formikProps.errors.ccname && formikProps.submitCount > 0 ? formikProps.errors.ccname : ''}
                      sx={inputStyles}
                      inputProps={{
                        autoComplete: 'cc-name'
                      }}
                    />
                  }
                  label="Name on card"
                />
                <PaymentElement/>
              </Grid>
            </Paper>
          }
        </Grid>

        <Grid container direction="column" alignItems="center" justifyContent="center" sx={{padding: 4}}>
          <Button type="submit" variant="contained" color="primary" disabled={!stripe}>
            Subscribe
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
}
