// To match stripe styles
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Form } from 'formik';
import { FormikProps } from 'formik/dist/types';
import React from 'react';
import { scrollToErrors } from '../../../../core/utils/html-helpers';
import LoadingBackdrop from '../../../../widgets/LoadingBackdrop';
import HomesSubForm from './HomesSubForm';
import { ISubscribeFormData } from './schemas';
import UserSubForm from './UserSubForm';


export interface ISetupForm {
  desktop: boolean;
  submitting: boolean;
  formikProps: FormikProps<ISubscribeFormData>;
}

export function SetupForm(props: ISetupForm) {
  const {desktop, formikProps, submitting} = props;
  return (
    <Form style={{width: '100%', zIndex: 2, position: 'relative'}}>

      <LoadingBackdrop open={submitting} text="Saving"/>

      <Grid
        container
        direction="column"
        sx={{
          padding: '48px 24px',
          '& > :not(:last-child)': {
            marginBottom: 16
          }
        }}
        alignItems="center"
      >

        <UserSubForm
          {...formikProps}
          desktop={desktop}
        />

        <HomesSubForm
          {...formikProps}
        />

        <Grid container direction="column" alignItems="center" justifyContent="center" sx={{padding: 4}}>
          <Button type="submit" variant="contained" color="primary" onClick={scrollToErrors}>
            Continue
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
}
