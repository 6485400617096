import React, { Context, createContext, ReactElement, useContext, useEffect, useState } from 'react';
import { ILocationResult, UtilsService } from '../../services/utils-service';


export const ApproxZipcode: Context<string | null> = createContext<string | null>(null);
export const useApproxZipcode = () => useContext(ApproxZipcode);

export interface IWHProviderData {
  approxZipcode: string | null;
}

export interface IWHProvider {
  children(props: IWHProviderData): (ReactElement | null);
}


export default function WHProvider(props: IWHProvider) {
  const { children } = props;
  const [approxZipcode, setApproxZip] = useState<string | null>(null);

  useEffect(() => {
    const API = 'AIzaSyBkslepbZIzrZNTNIOvYTkXJL8YlVbpZPA';
    const url = `https://www.googleapis.com/geolocation/v1/geolocate?key=${API}`;
    if (process.env.REACT_APP_ENV === 'development') {
      console.time('approxZip');
    }
    fetch(url, { method: 'POST' })
      .then(res => res.json())
      .then((data: { location: { lat: number; lng: number }, accuracy: number }) => {
        if (process.env.REACT_APP_ENV === 'development') {
          console.log('Approx Geolocation', data);
        }
        return UtilsService.locationFromLatLong(data.location.lat, data.location.lng);
      })
      .then((result: ILocationResult) => {
        if (process.env.REACT_APP_ENV === 'development') {
          console.log('Approx Location', result);
        }
        if (result.postal_code) {
          setApproxZip(result.postal_code);
        } else {
          console.error('No zipcode provided');
          setApproxZip(null);
        }
        if (process.env.REACT_APP_ENV === 'development') {
          console.timeEnd('approxZip');
        }
      })
      .catch((err: Error) => {
        console.error('Failed to get approx zipcode', err.message);
        setApproxZip(null);
        if (process.env.REACT_APP_ENV === 'development') {
          console.timeEnd('approxZip');
        }
      });
  }, []);

  return (
    <ApproxZipcode.Provider value={approxZipcode}>
      {children({ approxZipcode })}
    </ApproxZipcode.Provider>
  );

}
