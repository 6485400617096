import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getAuth, signOut, User } from 'firebase/auth';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-use';
import WHProvider from '../../core/providers/WHProvider';


const images = {
  desktop: `${process.env.PUBLIC_URL}/assets/promos/background.jpg`,
  mobile: `${process.env.PUBLIC_URL}/assets/promos/background-mobile.jpg`,
  logo: `${process.env.PUBLIC_URL}/assets/promos/welcome-home-logo-white-tagline.png`,
};

export default function WHOutlet(props: {user: User}) {
  const desktop = useMediaQuery('(min-width:600px)');
  const location = useLocation();
  const navigate = useNavigate();

  const isPlan = location.pathname && location.pathname.indexOf('/j/plans') > -1;
  const isPlanSetup = location.pathname && location.pathname.indexOf('/j/plans/checkout') > -1 || location.pathname && location.pathname.indexOf('/j/plans/setup') > -1 || location.pathname && location.pathname.indexOf('/j/plans/success') > -1;
  const isPlanSuccess = location.pathname && location.pathname && location.pathname.indexOf('/j/plans/success') > -1;

  const logout = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
      navigate('/signin');
    }).catch((error: Error) => {
      console.error('Logout failed', error);
      // An error happened.
    });
  }

  return (
    <WHProvider>
      {({ approxZipcode }) =>
        <Grid
          container
          direction="column"
          sx={{
            position: 'relative',
            minHeight: '100vh',
            width: '100vw',
            backgroundColor: '#fff',
            background: isPlanSetup ? isPlanSuccess ? 'linear-gradient(60deg, #47478E 0%, #4C7DFE 100%);' : '#fff' : `url(${desktop ? images.desktop : images.mobile})`,
            backgroundSize: 'cover',
            alignItems: desktop ? 'center' : 'flex-start'
          }}
        >
          {window.location.pathname === '/j/i' && <AppBar position="static" color="transparent" elevation={0} sx={{minHeight: 'unset'}}>
            <Toolbar sx={{minHeight: 'unset'}}>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1, opacity: 0}}>
                WelcomeHome
              </Typography>
              {props.user.isAnonymous ? <Button size="small" sx={{color: '#fff'}} component={Link} to={'/account'}>Signin</Button> : <Button size="small" sx={{color: '#fff'}} onClick={logout}>Logout</Button>}
            </Toolbar>
          </AppBar>}
          <Helmet>
            <meta charSet="utf-8"/>
            <title>Welcome Home</title>
            <link rel="canonical" href={window.location.pathname}/>
            <meta name="description" content={`Welcome Home - Your Home Done Right`}/>
          </Helmet>

          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{
              padding: isPlanSetup ? 'unset' : '24px 0',
              maxWidth: isPlan ? isPlanSetup ? 'unset' : 900 : 550,
              '& > *': {
                marginBottom: isPlanSetup ? 'unset' : 6
              }
            }}
          >
            <Outlet/>
          </Grid>
        </Grid>
      }
    </WHProvider>
  );

}
