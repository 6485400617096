import { Theme, useTheme } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import SwipeableViews from 'react-swipeable-views';
import { virtualize, autoPlay } from 'react-swipeable-views-utils';
import { mod } from 'react-swipeable-views-core';

const VirtualizeSwipeableViews: any = virtualize(autoPlay(SwipeableViews));


const IMAGES = [
  `${process.env.PUBLIC_URL}/assets/promos/landscaper.png`,
  `${process.env.PUBLIC_URL}/assets/promos/pool.png`,
  `${process.env.PUBLIC_URL}/assets/promos/winter.png`
];

function ImageCard(props: {index: number; image: string; width?: number}) {
  const {index, image, width} = {width: 412, ...props};
  const desktop = useMediaQuery('(min-width:600px)');
  return (

  <Grid key={`use-case-${index}`} container alignItems="center" justifyContent="center">
    <Box
      component="img"
      id={`use-case-${index}`}
      draggable={false}
      sx={{
        display: 'block',
        maxWidth: width,
        borderRadius: desktop ? 1 : 0,
        overflow: 'hidden',
        userSelect: 'none',
        pointerEvents: 'none',
        width: '100%'
      }}
      src={image}
    />
  </Grid>
  );
}

function slideRenderer(params: {index: number; key: string}) {
  const { index, key } = params;

  switch (mod(index, 3)) {
    case 0:
      return (
        <ImageCard key={key} index={0} image={IMAGES[0]}/>
      );

    case 1:
      return (
        <ImageCard key={key} index={1} image={IMAGES[1]}/>
      );

    case 2:
      return (
        <ImageCard key={key} index={2} image={IMAGES[2]}/>
      );

    default:
      return null;
  }
}


export default function UseCaseImages2(props: { width?: number }) {
  const { width } = {width: 414, ...props};
  const theme: Theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [active, setActive] = useState(false);
  const desktop = useMediaQuery('(min-width:600px)');
  const timer = useRef(-1);
  const ref = useRef<any>(null)

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const deactivate = () => {
    window.clearTimeout(timer.current);
    timer.current = window.setTimeout(() => {
      setActive(false);
    }, 2000);
  };

  return (
    <Grid
      id="use-cases"
      container
      direction="column"
      sx={{
        // maxWidth: 414,
        position: 'relative'
      }}
      alignItems="center"
      justifyContent="center"
    >
    <VirtualizeSwipeableViews
      ref={ref}
      slideRenderer={slideRenderer}
      axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
      index={activeStep}
      onChangeIndex={handleStepChange}
      autoplay={desktop ? true : !active}
      interval={5000}
      enableMouseEvents
      onMouseEnter={() => setActive(true)}
      onMouseLeave={deactivate}
      onTouchStart={() => setActive(true)}
      onTouchMove={() => setActive(true)}
      onTouchEnd={deactivate}
      onTouchCancel={deactivate}
    />
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          padding: 2,
          position: 'absolute',
          bottom: 0,
          zIndex: 6,
          '& > :not(:last-child)': {
            marginRight: '4px'
          }
        }}
      >
        {Array.from(Array(IMAGES.length).keys()).map((num: number, index: number) =>
          <Grid item key={`stepper-${num}`}
                onClick={() => {
                  console.log(index);
                  setActiveStep(index);
                }}
                sx={{
                  backgroundColor: num === mod(activeStep, 3) ? '#ffffff' : 'rgba(255, 255, 255, 0.5)',
                  width: 8,
                  height: 8,
                  transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                  borderRadius: '50%'
                }}
          />
        )}
      </Grid>
    </Grid>
  );
}
