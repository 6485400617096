import { IProfile } from '@jumpstart/core';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { AuthError, IdTokenResult, User, UserCredential } from 'firebase/auth';
import { doc, DocumentReference } from 'firebase/firestore';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useNavigate } from 'react-router-dom';
import { loginInAnonymously } from '../auth';
import { auth, db } from '../firebase';
import { IsAdminContext, ProfileContext } from '../hooks';
import Loading from '../Loading';


export interface IUserData {
  user: User | null;
  profile?: IProfile;
  isAdmin: boolean;
}

export interface IUserProvider {
  userData: [User | null | undefined, boolean, AuthError | undefined];

  children(props: IUserData): (ReactElement | null);
}

function AuthErrorButton(props: { error: AuthError | Error; title: string }) {
  const navigate = useNavigate();
  const { title, error } = props;
  return (
    <Alert
      severity="error"
      action={<Button color="inherit" size="small" onClick={() => navigate('/signin')}>Login</Button>}
    >
      {title}: {error.message}
    </Alert>
  );
}

function AuthenticatedUserProvider(props: IUserProvider & { user: User; }) {
  const { user, children } = props;
  const [isAdmin, setIsAdmin] = useState(false);
  const [profile] = useDocumentData<IProfile>(doc(db, 'wh-profiles', user.uid) as DocumentReference<IProfile>);

  const checkIfAdminUser = async (): Promise<boolean> => {
    if (!auth.currentUser) {
      return false;
    }
    const idTokenResult: IdTokenResult = await auth.currentUser.getIdTokenResult();
    return !!idTokenResult.claims.admin || !!idTokenResult.claims.isAdmin;
  };

  useEffect(() => {
    checkIfAdminUser().then((value: boolean) => {
      setIsAdmin(value);
    });
  }, [profile]);

  return (
    <ProfileContext.Provider value={profile}>
      <IsAdminContext.Provider value={isAdmin}>
        {
          children({
            user: user,
            profile: profile,
            isAdmin: isAdmin
          })
        }
      </IsAdminContext.Provider>
    </ProfileContext.Provider>
  );

}

export default function UserProvider(props: IUserProvider) {
  const { children } = props;
  // const [user, loading, authError] = props.userData;
  const [user, setUser] = useState(props.userData[0]);

  useEffect(() => {
    if (!props.userData[1] && !props.userData[0]) {
      loginInAnonymously()
        .then((cred: UserCredential) => {
          setUser(cred.user);
        });
    } else if (!!props.userData[0]) {
      setUser(props.userData[0]);
    }
  }, [props.userData[0], props.userData[1]]);

  if (!user) {
    // if (props.reroute) {
    //   console.error('User is not logged in, rerouting...');
    //   navigate(`/signin?redirect=${location.pathname}`);
    // }
    return (
      <ProfileContext.Provider value={undefined}>
        <IsAdminContext.Provider value={false}>
          {
            children({
              user: user ? user : null,
              isAdmin: false
            })
          }
        </IsAdminContext.Provider>
      </ProfileContext.Provider>
    );
  } else if (!user || !!props.userData[2]) {
    return (
      <AuthErrorButton
        error={props.userData[2] ? props.userData[2] : new Error('An unknown system error occurred')}
        title="Authentication Error"
      />
    );
  } else if (!!user) {
    return (
      <AuthenticatedUserProvider
        {...props}
        user={user}
      />
    );
  } else {
    return (
      <Loading show={true} text={'Loading...'}/>
    );
  }

}
