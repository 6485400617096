import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import React from 'react';
import { number, object } from 'yup';


export interface ICodeConfirmationForm {
  onSubmit(code: string): void;

  onReset(): void;

  margin: number;

  alt?: boolean;
}

export default function CodeConfirmationForm(props: ICodeConfirmationForm) {
  const { onSubmit, onReset, margin, alt } = props;

  const formik = useFormik({
    initialValues: {
      code: ''
    },
    validationSchema: object({ code: number().min(6).required('Confirmation code is required') }),
    onSubmit: (values) => {
      onSubmit(values.code);
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        sx={{
          maxWidth: '550px',
          width: '100%',
          '& > :not(:last-child)': {
            marginBottom: margin
          }
        }}
      >

        <TextField
          id="code"
          name="code"
          value={formik.values.code}
          onChange={formik.handleChange}
          error={(formik.touched.code || formik.submitCount > 0) && Boolean(formik.errors.code)}
          helperText={(formik.touched.code || formik.submitCount > 0) && formik.errors.code ? formik.errors.code : null}
          sx={{
            color: (theme) => alt ? 'inherit' : theme.palette.primary.contrastText,
            width: {
              xs: 320,
              sm: 350
            },
          }}
          variant="filled"
          label="Confirmation Code"
          inputProps={{
            autocomplete: 'one-time-code',
            inputMode: 'numeric',
            pattern: '[0-9]*'
          }}
        />

        <Button variant="contained" type="submit" color="primary">Confirm</Button>

        <Button
          variant="outlined"
          style={{ ...(alt ? {} : {borderColor: '#fff', color: '#fff' })}}
          onClick={() => onReset()}
        >
          Reset
        </Button>
      </Grid>
    </form>
  );

}
