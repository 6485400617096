import LocationOnIcon from '@mui/icons-material/LocationOn';
import CircularProgress from '@mui/material/CircularProgress';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import { FormikProps } from 'formik/dist/types';
import debounce from 'lodash/debounce';
import React, { Fragment, useMemo, useState } from 'react';
import { ILocationResult, UtilsService } from '../../services/utils-service';

export interface IZipcodeField extends FormikProps<{ zipcode: string | null }> {
  schema: any;
  onAutoZipcode(zipcode: string): void;
}

export default function ZipcodeField(props: IZipcodeField) {
  const { setFieldError, setFieldValue, schema, values, handleChange, errors, submitCount } = props;
  const [position, setPosition] = useState<GeolocationPosition | null>(null);
  const [error, setError] = useState<null | Error>(null);
  const [loadedLocation, setLoadedLocation] = useState(false);
  const [locationLoading, setLocationLoading] = useState(false);
  const handleLocationError = (error: Error) => {
    setError(error);
    setLoadedLocation(true);
    setLocationLoading(false);
    console.error('Could not get zip', error.message);
    // setFieldError('zipcode', `Failed to get zip: ${eror.message}`);
  };

  const handleLocationResult = (result: ILocationResult) => {
    setLocationLoading(false);
    console.log('Location Result', result);
    if (result.error) {
      console.error('Failed to get zip', result.error);
      setFieldError('zipcode', `Failed to get zip: ${result.error}`);
    }
    if (result.postal_code) {
      setFieldValue('zipcode', result.postal_code);
      setFieldError('zipcode', undefined);
    }
    if (result.locality) {
      setFieldValue('city', result.locality);
    }
  };

  const reloadLocationFromZip = useMemo(
    () =>
      debounce((zipcode: string) => {
        setLocationLoading(true);
        UtilsService.locationFromZip(zipcode)
          .then(handleLocationResult)
          .catch(handleLocationError);
      }, 500),
    []
  );

  const handleZipcodeChange = (zipcode: string) => {
    if (schema.fields.zipcode.isValidSync(zipcode)) {
      reloadLocationFromZip(zipcode);
    }
  };

  const showPosition = async (_position: GeolocationPosition) => {
    setPosition(_position);
    const lat = _position.coords.latitude;
    const long = _position.coords.longitude;
    setLocationLoading(true);
    await UtilsService.locationFromLatLong(lat, long)
      .then((result: ILocationResult) => {
        setLoadedLocation(true);
        handleLocationResult(result);
      })
      .catch(handleLocationError);
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.error('Sorry, but Geolocation is not supported by this browser.');
    }
  };

  // useEffect(() => {
  //   if (!loadedLocation) {
  //     console.log('ASKING ZIP');
  //     getLocation();
  //   }
  // }, []);

  return (

    <FormControl
      variant="outlined"
      sx={{ maxWidth: 550 }}
    >
      <InputLabel htmlFor="zipcode">Zipcode</InputLabel>
      <FilledInput
        id="zipcode"
        name="zipcode"
        placeholder={`98128`}
        value={values.zipcode}
        onChange={handleChange}
        onBlur={(event) => {
          // @TODO
          if (event.target.value !== values.zipcode) {
            handleZipcodeChange(event.target.value);
          }
        }}
        sx={{ maxWidth: 350, width: '100%' }}
        endAdornment={
          <InputAdornment position="end">{locationLoading ?
            <CircularProgress size={14} color="info"/> :
            <Fragment>
              <IconButton onClick={getLocation}><LocationOnIcon/></IconButton>
            </Fragment>}</InputAdornment>
        }
        inputProps={{
          autoComplete: 'shipping postal-code',
          pattern: '[0-9]{5}',
          maxlength: '5'
        }}
      />
      <FormHelperText error={!!errors.zipcode && submitCount > 0}>
        {errors.zipcode}
      </FormHelperText>
    </FormControl>
  );

}
