import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import Fade from '@mui/material/Fade';
import Grid, { GridProps } from '@mui/material/Grid';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';
import React, { MutableRefObject, useEffect, useState } from 'react';

export interface IFloatingPointer extends GridProps {
  parentRef: MutableRefObject<HTMLElement | null>;
  iconStyles?: any;
}

/**
 * Used to navigate the user down to an element
 */
export default function FloatingPointer(props: IFloatingPointer) {
  const { parentRef, iconStyles} = {iconStyles: {}, ...props};
  const [show, setShow] = useState(false);

  useEffect(() => {
    // Lazy load additional properties only when user views the bubble
    const observer = new window.IntersectionObserver(([entry]) => {
      setShow(!entry.isIntersecting);
    }, {
      root: null,
      threshold: 0
    });
    if (parentRef.current) {
      observer.observe(parentRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [parentRef]);

  return (
    <Fade in={show} timeout={1000} style={{ transitionDelay: show ? '500ms' : '0ms' }}>
      <Grid
        sx={{
          ...(props.sx ? props.sx : {}),
          animationDuration: '2s',
          animationIterationCount: 'infinite',
          animationTimingFunction: 'ease-in-out',
          animationName: 'bounce',
          '@keyframes bounce': {
            '0%': {
              transform: 'translateY(0)'
            },
            '50%': {
              transform: 'translateY(10px)'
            },
            '100%': {
              transform: 'translateY(0)'
            }
          }
        }}>
        <KeyboardDoubleArrowDownIcon style={iconStyles}/>
      </Grid>
    </Fade>
  );
}
