import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { StripePrice, StripeProduct, StripeSubscription } from '../../../../core/schemas';
import { COLORS } from '../../../../core/theme';
import { IMAGE_PLACEHOLDER } from '../../../../core/utils';

export interface IOrderDetails {
  desktop: boolean;
  subscription: StripeSubscription;
}

export default function OrderDetails(props: IOrderDetails) {
  const {desktop, subscription} = props;

  return (
    <List
      sx={{width: '100%', maxWidth: desktop ? 360 : 'unset', backgroundColor: 'transparent'}}
      aria-label="contacts"
    >
      {subscription.latest_invoice.lines.data
        .sort((a, b) => {
          const x = a.price && a.price.recurring ? 1 : 0;
          const y = b.price && b.price.recurring ? 1 : 0;
          return y - x;
        })
        .map((line) => <ListItem key={line.id} disablePadding={false} sx={{backgroundColor: 'transparent'}}>
          {line.price && line.price.recurring && <ListItemIcon>
              <Avatar variant="rounded" src={`${process.env.PUBLIC_URL}/assets/PNG/Icon_64x64.png`}
                      sx={{width: 42, height: 42}}/>
          </ListItemIcon>}
          <ListItemText
            inset={!!(line.price && !line.price.recurring)}
            primaryTypographyProps={{component: 'div'}}
            primary={
              <Grid container direction="row" justifyContent="space-between">
                <Typography variant="subtitle2" sx={{
                  width: 200,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>{line.description}</Typography>

                <Typography variant="subtitle2">${(line.amount / 100).toFixed(2)}</Typography>
              </Grid>
            }
            secondary={line.price && line.price.recurring && (line.price.recurring as any).interval ? `Billed ${(line.price.recurring as any).interval}ly` : ''}
            secondaryTypographyProps={{variant: 'caption'}}
          />
        </ListItem>)}
      <ListItem disablePadding={false} sx={{backgroundColor: 'transparent'}}>
        <ListItemText
          inset
          primary={
            <Grid container direction="row" justifyContent="space-between">
              <Typography variant="subtitle2">Subtotal</Typography>
              <Typography variant="subtitle2">${(subscription.latest_invoice.subtotal / 100).toFixed(2)}</Typography>
            </Grid>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li"/>
      <ListItem disablePadding={false} sx={{backgroundColor: 'transparent'}}>
        <ListItemText
          inset
          primary={
            <Grid container direction="row" justifyContent="space-between" alignItems="center" wrap="nowrap">
              <Grid item>

                <Grid container direction="row" alignItems="center">
                  <Typography variant="subtitle2" color="text.secondary" sx={{marginRight: 1}}>Tax</Typography>
                  <Tooltip title="Tax is determined by your billing information">
                    <InfoOutlinedIcon sx={{color: COLORS.gray3, fontSize: 14}} fontSize="small"/>
                  </Tooltip>
                </Grid>
              </Grid>
              <Typography variant="caption" color="text.secondary">${typeof subscription.latest_invoice.tax === 'number' ? ((subscription.latest_invoice.tax/100).toFixed(2)) : '0.00'}</Typography>
            </Grid>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li"/>
      <ListItem disablePadding={false} sx={{backgroundColor: 'transparent'}}>
        <ListItemText
          inset
          primary={
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle2">Total due today</Typography>
              <Typography variant="subtitle2">${(subscription.latest_invoice.total / 100).toFixed(2)}</Typography>
            </Grid>
          }
        />
      </ListItem>
    </List>
  )
}