import { generateZipcodes } from './utils';

export const kingCountyZipcodes: string[] = [
  ...generateZipcodes(98001, 98199),
];

export const zipToLocale = (zip: string) => {
  if (kingCountyZipcodes.indexOf(zip) > -1) {
    return 'seattle';
  }

  return null;
}
