import { IProfile, MathUtils } from '@jumpstart/core';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Zoom from '@mui/material/Zoom';
import { User } from 'firebase/auth';
import { collection, doc, getDoc, Query, setDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';
import { Link } from 'react-router-dom';
import { db } from '../../../core/firebase';
import { COLORS } from '../../../core/theme';

const desktopSize = 312;
const mobileSize = 245;

export default function WHSuccessScreen(props: { user: User }) {
  const [found, setFound] = useState<IProfile | null>(null);
  const [assigned, setAssigned] = useState(false)
  const [houseManagers] = useCollectionData<IProfile>(collection(db,'wh-house-managers') as Query<IProfile>);
  const [activeHouseManager, loadingActiveHouseManager] = useDocumentData<{houseManager: string}>(doc(db, `wh-profiles/${props.user.uid}/assignments/house-manager`) as any);
  const desktop = useMediaQuery('(min-width:600px)');

  const getRandomHouseManager = (managers: IProfile[]): IProfile => {
    const hm = managers[MathUtils.randomInt(0, managers.length - 1)]
    if (hm.img && hm.firstName) {
      return hm;
    } else {
      return getRandomHouseManager(managers);
    }
  }

  useEffect(() => {
    if (found && (!activeHouseManager || activeHouseManager.houseManager !== found.id)) {
      console.log('Saving House Manager...');
      setDoc(doc(db, `wh-profiles/${props.user.uid}/assignments/house-manager`), {houseManager: found.id, assignedOn: new Date().toISOString()})
        .then(() => {
          setAssigned(true);
          console.log('Assigned!');
        })
        .catch((err: Error) => {
          console.error('Failed to assign', err.message);
        })
    }
  }, [found])

  useEffect(() => {
    if (houseManagers && !loadingActiveHouseManager) {
      if (activeHouseManager) {
        const index = houseManagers.findIndex(hm => hm.id === activeHouseManager.houseManager);
        if (index > -1) {
          setFound(houseManagers[index]);
        } else {
          setFound(getRandomHouseManager(houseManagers));
        }
      } else {
        setFound(getRandomHouseManager(houseManagers));
      }
    }
  }, [houseManagers, loadingActiveHouseManager]);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        padding: 5,
        minHeight: '100vh',
        '& > :not(:last-child)': {
          marginBottom: 4
        }
      }}
    >
      <Fade in={true}>
        <Box>
          <Typography variant="h3" sx={{color: '#fff', opacity: 0.7, textAlign: 'center'}}>{!found ? 'Assigning Your' : 'Assigned'} Dedicated
            House Manager</Typography>
        </Box>
      </Fade>
      <Grid
        container
        sx={{
          width: desktop ? desktopSize : mobileSize,
          height: desktop ? desktopSize : mobileSize,
          backgroundColor: COLORS.gray2,
          borderRadius: '50%',
          position: 'relative',
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Fade in={!found}>
          <CircularProgress sx={{
            position: 'absolute',
            zIndex: 2,
            color: '#fff'
          }} size={(desktop ? desktopSize : mobileSize) - 1} thickness={0.8}/>
        </Fade>

        <Fade in={!found}>
          <Box
            component="img"
            src={`${process.env.PUBLIC_URL}/assets/unknown.png`}
            sx={{
              position: 'absolute',
              width: (desktop ? desktopSize : mobileSize) - 71,
              height: (desktop ? desktopSize : mobileSize) - 71,
              zIndex: 1,
              borderRadius: '50%'
            }}
          />
        </Fade>
        <Zoom in={!!found} style={{transitionDelay: '300ms'}}>
          <Box
            component="img"
            src={found && found.img ? found.img : `${process.env.PUBLIC_URL}/assets/unknown.jpg`}
            sx={{
              position: 'absolute',
              zIndex: 3,
              width: (desktop ? desktopSize : mobileSize),
              height: (desktop ? desktopSize : mobileSize),
              borderRadius: '50%'
            }}
          />
        </Zoom>
      </Grid>
      <Fade in={!!found} style={{transitionDelay: '800ms'}}>
        <Typography variant="h1" sx={{color: '#fff'}}>{found && found.firstName ? found.firstName : 'Q'}</Typography>
      </Fade>
      <Fade in={!!found} style={{transitionDelay: '1300ms'}}>
        <Box>
          <Typography variant="h5" sx={{color: '#fff', opacity: 0.7, textAlign: 'center'}}>Happy to meet you, I will reach out to you shortly</Typography>
        </Box>
      </Fade>
      <Fade in={assigned} style={{transitionDelay: '1800ms'}}>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="medium"
            component={Link}
            to="/services"
            sx={{
              minWidth: 250,
              marginTop: 4,
              color: '#000',
              // backgroundColor: '#00aeef',
              backgroundColor: '#fff',
              '&:hover, &:active': {
                // backgroundColor: '#00aeef'
                backgroundColor: '#fff'
              }
            }}
          >
            Request Services
          </Button>
          <Button
            variant="outlined"
            size="medium"
            component={Link}
            to="/account"
            sx={{
              minWidth: 250,
              marginTop: 4,
              color: '#fff',
              borderColor: '#fff',
              backgroundColor: 'transparent',
              '&:hover, &:active': {
                backgroundColor: 'transparent'
              }
            }}
          >
            Account Page
          </Button>
        </Grid>
      </Fade>
    </Grid>
  );
}