const human = require('humanparser');

export interface IHumanParse {
  firstName: string;
  lastName: string;
  fullName: string;
  middleName?: string;
  salutation?: string;
  suffix?: string;
}

export default class ProfileService {

  public static parseFullName(fullName: string): IHumanParse {
    return human.parseName(fullName);
  }

}