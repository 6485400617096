import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import React, { Fragment } from 'react';
import { COLORS } from '../../../../core/theme';
import OrderEstimate from './OrderEstimate';

export default function LoadingCheckout(props: { desktop: boolean }) {
  const {desktop} = props;

  return (
    <Grid
      container
      direction={desktop ? 'row' : 'column'}
      wrap="nowrap"
      sx={{
        '& > :not(:last-child)': {
          marginBottom: desktop ? 'unset' : '1.5rem'
        }
      }}
    >
      <Grid item xs={desktop ? 6 : 12} sx={{backgroundColor: desktop ? COLORS.gray6 : 'inherit'}}>
        <Grid
          container
          direction="column"
          alignItems="center"
          sx={{
            ...(desktop ? {
              position: 'fixed',
              left: 0,
              width: '50%',
            } : {}),
            paddingTop: desktop ? 12 : 'unset',
            '& > *': {
              maxWidth: 380,
            },
            '& > :not(:last-child):not(.backdrop)': {
              marginBottom: desktop ? 12 : 4
            }
          }}
        >
          <Grid container sx={{
            ...(desktop ? {} : {position: 'fixed', top: 0, zIndex: 4})
          }}>
            <Skeleton variant="rectangular" width="100%" height={80}/>
          </Grid>
          {!desktop && <Box sx={{width: '100%', height: 80, opacity: 0}}/>}

          <Grid
            container
            direction="column"
            sx={{
              padding: desktop ? 'unset' : '0 24px',
              '& > :not(:last-child)': {
                marginBottom: 8
              }
            }}
          >
            <Grid
              container
              direction="column"
              sx={{
                '& > :not(:last-child)': {
                  marginBottom: desktop ? 4 : 8
                }
              }}
            >
              {!desktop && <Grid
                  container
                  direction="column"
                  alignItems="center"
              >
                  <Skeleton variant="circular" width={120} height={120}/>
              </Grid>}

              <Grid container direction="column" alignItems="center">
                <Skeleton variant="text" width={180} height={22}/>
                <Skeleton variant="text" width={185} height={42}/>
              </Grid>


              <Grid container direction="column" alignItems="center">
                <Skeleton variant="text" width="100%" height={22}/>
                <Skeleton variant="text" width="100%" height={42}/>
              </Grid>

            </Grid>
          </Grid>
        </Grid>


      </Grid>

      <Grid
        item
        xs={desktop ? 6 : 12}
        sx={{
          minHeight: '100vh',
          backgroundColor: '#fff',
          '&:before': desktop ? {
            content: '" "',
            boxShadow: '15px 0 30px 0 rgb(0 0 0 / 18%)',
            height: '100%',
            width: '50%',
            background: '#fff',
            position: 'fixed',
            top: 0,
            right: 0,
            animationFillMode: 'both',
            transformOrigin: 'right'
          } : {}
        }}
      >
        <Grid container direction="column" alignItems="center"
              sx={{
                padding: 6,
                '& > :not(:last-child)': {
                  marginBottom: desktop ? 4 : 8
                }
              }}
        >
          <Skeleton variant="rectangular" width={450} height={200}/>
          <Skeleton variant="rectangular" width={450} height={200}/>
          <Skeleton variant="text" width={350} height={44}/>
          <Skeleton variant="text" width={350} height={44}/>
        </Grid>
      </Grid>

    </Grid>

  )
}