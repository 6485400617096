const bucket = 'https://storage.googleapis.com/jumpstart-o5ofk';
export const SILHOUETTES = `${bucket}/silhouettes/breeds`;
export const IMAGE_PLACEHOLDER = `${process.env.PUBLIC_URL}/assets/placeholder.png`;

export const isPlaceholder = (img: string): boolean => (
  img.indexOf(`${process.env.PUBLIC_URL}/assets/profiles`) > -1
  || img.indexOf(SILHOUETTES) > -1
  || img.indexOf('UNKNOWN') > -1
  || img.indexOf('placeholder') > -1
)
