import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { StripePrice, StripeProduct, StripeSubscription } from '../../../../core/schemas';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { COLORS } from '../../../../core/theme';
import { IMAGE_PLACEHOLDER } from '../../../../core/utils';
import SmartImage from '../../../../widgets/react-extensions/SmartImage';

export interface IProductSummary {
  desktop: boolean;
  product: StripeProduct;
  price: StripePrice;

  onToggle(): void;
}

export default function ProductSummary(props: IProductSummary) {
  const {desktop, onToggle, product, price} = props;

  const resolveImage = () => {
    if (product.images.length > 0) {
      return product.images[0]
    } else {
      return IMAGE_PLACEHOLDER;
    }
  }

  const image = resolveImage();

  return (
    <Grid
      container
      direction="column"
      sx={{
        '& > :not(:last-child)': {
          marginBottom: desktop ? 4 : 8
        }
      }}
    >

      {!desktop && <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{position: 'relative'}}
      >
          <SmartImage style={{width: 120, height: 120}} src={image}/>
          <Grid
              container
              direction="column"
              alignItems="center"
              sx={{
                position: 'absolute',
                zIndex: 2,
                bottom: -10
              }}
          >
              <Button
                  variant="contained"
                  size="small"
                  endIcon={<ChevronRightIcon/>}
                  onClick={onToggle}
                  sx={{
                    width: 95,
                    height: 20,
                    padding: '4px 0px 4px 22px',
                    backgroundColor: COLORS.gray6,
                    color: (theme) => theme.palette.text.primary,
                    boxShadow: '0 2px 5px 0 rgb(50 50 93 / 10%), 0 1px 1px 0 rgb(0 0 0 / 7%)',
                    '&:focus, &:hover': {
                      backgroundColor: COLORS.gray6,
                      color: (theme) => theme.palette.primary.main,
                    }
                  }}
              >
                  Details
              </Button>
          </Grid>
      </Grid>
      }

      <Grid
        container
        direction="column"
        alignItems={desktop ? 'unset' : 'center'}
      >

        <Typography variant="subtitle1" color="text.secondary">Subscribe to {product.name}</Typography>
        {typeof price.unit_amount === 'number' && typeof price.recurring === 'object' &&
            <Grid item>
            <Grid container direction="row" alignItems="center">
                <Typography variant="h1" sx={{marginRight: 2}}>${(price.unit_amount / 100).toFixed(2)}</Typography>
                <Typography variant="body2" color="text.secondary"
                            sx={{lineHeight: '115%'}}>per<br/>{price.recurring?.interval}</Typography>
            </Grid></Grid>}
      </Grid>

      {product.description && <Typography variant="body2" color="text.secondary" sx={{textAlign: desktop ? 'unset' : 'center'}}>{product.description}</Typography>}

    </Grid>
  )
}