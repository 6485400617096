import { COLORS } from '../../../../core/theme';

export const labelStyles = {
  maxWidth: 350,
  width: '100%',
  '.MuiFormControl-root': {
    width: '100%',
  },
  '.MuiInputBase-input': {
    color: COLORS.darkBlue,
    paddingTop: '15px',
    paddingBottom: '12px',
  }
}
export const inputStyles = {
  maxWidth: 350,
  width: '100%',
  '.MuiInputBase-input': {
    color: COLORS.darkBlue,
    paddingTop: '15px',
    paddingBottom: '12px',
  }
}