import { Address, IAddress } from '@jumpstart/core';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FormikErrors, FormikProps } from 'formik/dist/types';
import React, { Fragment, useState } from 'react';
import { getDetails, Suggestion } from 'use-places-autocomplete';
import { COLORS } from '../../../../core/theme';
import PlacesSearch from '../../../../widgets/PlacesSearch';
import { kingCountyZipcodes } from '../../../../core/utils/zipcodes/zips';
import HomeLockSubForm from './HomeLockSubForm';
import { IHome, ISubscribeFormData } from './schemas';
import { inputStyles, labelStyles } from './styles';
import GeocoderAddressComponent = google.maps.GeocoderAddressComponent;

export interface IHomeSubForm extends FormikProps<ISubscribeFormData> {
  homeValues: IHome;
  homeErrors: FormikErrors<IHome>;

  // homes[0]
  fieldPath: string;


  showDelete: boolean;
  onDelete(): void;
}

export default function HomeSubForm(props: IHomeSubForm) {
  const {fieldPath, submitCount, handleChange, showDelete, setFieldValue, homeErrors, homeValues, onDelete} = props;
  const [manual, setManual] = useState(false);

  const formatAddress = (address: IAddress) => {
    let addr = '';
    if (address.line1) {
      addr += address.line1
    }
    if (address.line2) {
      addr += ' '
      addr += address.line2
    }
    if (address.region) {
      addr += ', '
      addr += address.region
    }
    if (address.province) {
      addr += ', '
      addr += address.province
    }
    if (address.country) {
      addr += ', '
      addr += address.country
    }
    return addr;
  }

  const convertToAddress = (components: GeocoderAddressComponent[], homeValues: IHome): IAddress => {
    const address: IAddress = Address.defaultProps();
    address.name = homeValues.name;
    let streetNumber = '';
    let route = '';
    for (const component of components) {
      for (const type of component.types) {
        switch (type) {
          case 'street_number':
            streetNumber = component.short_name;
            break;
          case 'route':
            route = component.short_name;
            break;
          case 'room':
          case 'floor':
            address.line2 = component.short_name;
            break;
          case 'locality':
            address.province = component.short_name;
            break;
          case 'administrative_area_level_1':
            address.region = component.short_name;
            address.state = component.short_name;
            break;
          case 'country':
            address.country = component.short_name;
            break;
          case 'postal_code':
            address.zip = component.short_name;
        }
      }
    }
    address.line1 = `${streetNumber} ${route}`;

    return address;
  }

  // Use google place API to auto complete a suggestion
  const handlePlace = (suggest: Suggestion) => {
    getDetails({placeId: suggest.place_id})
      .then((details: google.maps.places.PlaceResult | string) => {
        if (typeof details === 'string') {
          console.error('What to do with this?', details);
        } else if (details.address_components) {
          const address = convertToAddress(details.address_components, homeValues);
          (address as any).formatted = details.formatted_address;
          console.log('New Address', address);
          setFieldValue(`${fieldPath}`, {
            ...homeValues,
            ...address
          });
        } else {
          console.error('No "address_components" in', details);
        }
      })
  }

  return (
    <Paper
      elevation={1}
      sx={{
        width: '100%',
        maxWidth: 450,
        '& > *': {
          padding: '48px 24px'
        }
      }}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        sx={{
          position: 'relative',
          // backgroundColor: COLORS.lightGreen,
          borderRadius: 4,
          '& > :not(:last-child)': {
            marginBottom: 3
          }
        }}
      >

        <Grid
          container
          justifyContent="flex-end"
          sx={{
            position: 'absolute',
            top: 12,
            right: 24
          }}
        >
          <IconButton
            sx={{visibility: showDelete ? 'visible' : 'hidden'}}
            color="primary"
            onClick={onDelete}
            edge="end"
          >
            <DeleteOutlineOutlinedIcon/>
          </IconButton>
        </Grid>


        <FormControlLabel
          sx={labelStyles}
          control={
            <TextField
              onChange={handleChange}
              id={`fieldPath.name`}
              name={`fieldPath.name`}
              variant="filled"
              value={homeValues.name}
              error={!!homeErrors.name && submitCount > 0}
              helperText={homeErrors.name && submitCount > 0 ? homeErrors.name : ''}
              placeholder="House Name"
              sx={inputStyles}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                  </InputAdornment>
                )
              }}
            />
          }
          label="Home Name"
        />

        {homeValues.zip && !homeErrors.zip && kingCountyZipcodes.indexOf(homeValues.zip) === -1 &&
            <Alert severity="warning" variant="filled"
                   sx={{maxWidth: 350, '.MuiAlert-message': {maxWidth: 284, width: '100%'}}}>This home is currently
                outside our field of service ({homeValues.zip}).</Alert>}

        {
          !manual ?
            <Fragment>
              <PlacesSearch
                onChange={(suggestion: Suggestion) => handlePlace(suggestion)}
                FormControlLabelProps={{label: 'Home Address', sx: labelStyles}}
                TextFieldProps={{
                  variant: 'filled',
                  error: Object.keys(homeErrors).length > 0 && submitCount > 0,
                  helperText: homeErrors.line1 && submitCount > 0 ? Object.keys(homeErrors)
                    .filter(key => key !== 'accessDetails' && key !== 'lockProductId' && key !== 'hasDigitalLock')
                    .map(key => `${(homeErrors as any)[key]}`)
                    .join(', ') : '',
                  placeholder: formatAddress(homeValues),
                  sx: {inputStyles},
                }}
                useFormControl={true}
              />

              <Button size="small" color="primary" onClick={() => setManual(true)}>Can't find address? Enter
                manually</Button>

            </Fragment>
            :
            <Fragment>

              <FormControlLabel
                sx={labelStyles}
                control={
                  <TextField
                    onChange={handleChange}
                    id={`${fieldPath}.line1`}
                    name={`${fieldPath}.line1`}
                    variant="filled"
                    value={homeValues.line1}
                    error={!!homeErrors.line1 && submitCount > 0}
                    helperText={homeErrors.line1 && submitCount > 0 ? homeErrors.line1 : ''}
                    placeholder="Line 1"
                    sx={inputStyles}
                    inputProps={{
                      autoComplete: 'shipping address-line1'
                    }}
                  />
                }
                label="Address"
              />
              <TextField
                onChange={handleChange}
                id={`${fieldPath}.line2`}
                name={`${fieldPath}.line2`}
                variant="filled"
                value={homeValues.line2}
                placeholder="Line 2"
                error={!!homeErrors.line2 && submitCount > 0}
                helperText={homeErrors.line2 && submitCount > 0 ? homeErrors.line2 : ''}
                sx={inputStyles}
                inputProps={{
                  autoComplete: 'shipping address-line2',
                }}
              />

              <Grid container spacing={2} sx={{maxWidth: 350}}>
                <Grid item xs={6}>
                  <FormControlLabel
                    sx={labelStyles}
                    control={
                      <TextField
                        onChange={handleChange}
                        id={`${fieldPath}.province`}
                        name={`${fieldPath}.province`}
                        placeholder="Seattle"
                        variant="filled"
                        value={homeValues.province}
                        error={!!homeErrors.province && submitCount > 0}
                        helperText={homeErrors.province && submitCount > 0 ? homeErrors.province : ''}
                        sx={inputStyles}
                        inputProps={{
                          autoComplete: 'shipping locality'
                        }}
                      />
                    }
                    label="City"
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormControlLabel
                    sx={labelStyles}
                    control={
                      <TextField
                        onChange={handleChange}
                        id={`${fieldPath}.region`}
                        name={`${fieldPath}.region`}
                        variant="filled"
                        placeholder="WA"
                        value={homeValues.region}
                        error={!!homeErrors.region && submitCount > 0}
                        helperText={homeErrors.region && submitCount > 0 ? homeErrors.region : ''}
                        sx={inputStyles}
                        inputProps={{
                          autoComplete: 'shipping region'
                        }}
                      />
                    }
                    label="State"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{maxWidth: 350}}>
                <Grid item xs={6}>
                  <FormControlLabel
                    sx={labelStyles}
                    control={
                      <TextField
                        onChange={handleChange}
                        id={`${fieldPath}.zip`}
                        name={`${fieldPath}.zip`}
                        variant="filled"
                        placeholder="98112"
                        value={homeValues.zip}
                        error={!!homeErrors.zip && submitCount > 0}
                        helperText={homeErrors.zip && submitCount > 0 ? homeErrors.zip : ''}
                        sx={inputStyles}
                        inputProps={{
                          autoComplete: 'shipping postal-code',
                          maxLength: 5
                        }}
                      />
                    }
                    label="Zip"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    sx={labelStyles}
                    control={
                      <TextField
                        disabled
                        onChange={handleChange}
                        id={`${fieldPath}.country`}
                        name={`${fieldPath}.country`}
                        variant="filled"
                        placeholder="US"
                        value={homeValues.country}
                        error={!!homeErrors.country && submitCount > 0}
                        helperText={homeErrors.country && submitCount > 0 ? homeErrors.country : 'Only available in the US'}
                        sx={inputStyles}
                        inputProps={{
                          autoComplete: 'shipping country'
                        }}
                      />
                    }
                    label="Country"
                  />
                </Grid>
              </Grid>

              <Button size="small" color="primary" onClick={() => setManual(false)}>Search address</Button>
            </Fragment>
        }

        {/*{process.env.NODE_ENV === 'development' && <pre style={{*/}
        {/*  padding: '12px',*/}
        {/*  backgroundColor: COLORS.gray6,*/}
        {/*  borderRadius: 4,*/}
        {/*  maxWidth: 350,*/}
        {/*  overflow: 'scroll'*/}
        {/*}}>{JSON.stringify(homeValues, null, 2)}</pre>}*/}


        <HomeLockSubForm
          {...props}
          homeValues={homeValues}
          homeErrors={homeErrors}
          fieldPath={fieldPath}
        />

        <Typography variant="caption">We currently do not service condos at this time</Typography>
      </Grid>
    </Paper>

  )
}