import React, { useEffect, useState } from 'react';
import { IMAGE_PLACEHOLDER } from '../../core/utils';

export interface ISmartImage extends Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'onError' | 'onLoad'> {
  placeholder?: string;
  onLoaded?():void;
}

export default function SmartImage(props: ISmartImage) {
  const {placeholder} = {placeholder: IMAGE_PLACEHOLDER, ...props}
  const [src, setSrc] = useState(props.src);

  useEffect(() => {
    setSrc(props.src);
  }, [props.src]);

  return(<img
    {...props}
    src={src}
    onError={() => setSrc(placeholder)}
  />)
}
