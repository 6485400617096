import Alert from '@mui/material/Alert';
import { ThemeProvider } from '@mui/material/styles';
import React, { Suspense } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import './App.css';
import ErrorBoundary from './core/ErrorBoundary';
import { auth } from './core/firebase';
import Loading from './core/Loading';
import UserProvider, { IUserData } from './core/providers/UserProvider';
import { MATERIAL_THEME } from './core/theme';
import { User } from 'firebase/auth';
import routes from './routes';

function Main(props: IUserData & {user: User}) {
  const { user, isAdmin } = props;
  const routing = useRoutes(routes(user, isAdmin));
  return routing;
}

function App() {
  const [user, loading, error] = useAuthState(auth);
  return (
    <ThemeProvider theme={MATERIAL_THEME}>
      <UserProvider userData={[user, loading, error as any]}>
        {(userData: IUserData) =>
          <Router>
            <Suspense fallback={<Loading show={loading} text="Your home, done right."/>}>
              <ErrorBoundary>
                {error ? <Alert severity="error">{error ? error.message : ''}</Alert> : null}
                {loading || !userData.user ? <Loading show={true} text="Your home, done right."/> : <Main
                  user={userData.user}
                  profile={userData.profile}
                  isAdmin={userData.isAdmin}
                />}
              </ErrorBoundary>
            </Suspense>
          </Router>
        }
      </UserProvider>
    </ThemeProvider>
  );
}

export default App;
