import Typography from '@mui/material/Typography';
import React, { memo } from 'react';

function PhoneTermsText() {
  return (
    <Typography variant="caption" style={{ color: '#fff' }}>
      US numbers only at this time. Data rates may apply. Text STOP to cancel, HELP for help.
    </Typography>
  );
}

export default memo(PhoneTermsText);
