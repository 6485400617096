import { Address } from '@jumpstart/core';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FieldArray } from 'formik';
import { FormikProps } from 'formik/dist/types';
import React from 'react';
import { useApproxZipcode } from '../../../../core/providers/WHProvider';
import HomeSubForm from './HomeSubForm';
import { IHome, ISubscribeFormData } from './schemas';

export interface IHomesSubForm extends FormikProps<ISubscribeFormData> {
}

export default function HomesSubForm(props: IHomesSubForm) {
  const {values, errors, submitCount, handleChange, setFieldValue} = props;
  const approxZipcode = useApproxZipcode();

  const defaultHome = (): IHome => {
    const home: IHome = {
      hasDigitalLock: true,
      accessDetails: '',
      ...Address.defaultProps(),
      name: `Home ${(values.homes ? values.homes : []).length + 1}`
    };
    if (values.homes && values.homes.length > 0) {
      const previousHome: IHome = values.homes[0];
      home.zip = previousHome.zip ? previousHome.zip : approxZipcode;
      home.region = previousHome.region;
      home.state = previousHome.state;
      home.province = previousHome.province;
    } else {
      home.zip = approxZipcode;
    }
    (home.country as any) = 'US';
    return home;
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      sx={{
        '& > :not(:last-child)': {
          marginBottom: 1
        }
      }}
    >

      <Typography variant="subtitle2" color="text.secondary"
                  sx={{textAlign: 'left', width: '100%', maxWidth: 450}}>Homes</Typography>

      <FieldArray
        name="homes">
        {(homeArrayHelper) =>
          <Grid
            container
            direction="column"
            alignItems="center"
            sx={{
              '& > :not(:last-child)': {
                marginBottom: 4
              }
            }}
          >

            {(values.homes ? values.homes : []).map((home: IHome, index: number) => {
                const homeError: any = errors.homes && (errors as any).homes[index] ? (errors as any).homes[index] : {};
                return (
                  <HomeSubForm
                    {...props}
                    showDelete={index !== 0}
                    onDelete={() => homeArrayHelper.remove(index)}
                    homeValues={home}
                    homeErrors={homeError}
                    fieldPath={`homes[${index}]`}
                  />
                );
                // return (
                //   <Paper
                //     key={`home-${index}`}
                //     elevation={1}
                //     sx={{
                //       width: '100%',
                //       maxWidth: 450,
                //       '& > *': {
                //         padding: '48px 24px'
                //       }
                //     }}
                //   >
                //     <Grid
                //       container
                //       direction="column"
                //       alignItems="center"
                //       sx={{
                //         position: 'relative',
                //         // backgroundColor: COLORS.lightGreen,
                //         borderRadius: 4,
                //         '& > :not(:last-child)': {
                //           marginBottom: 3
                //         }
                //       }}
                //     >
                //
                //       <Grid
                //         container
                //         justifyContent="flex-end"
                //         sx={{
                //           position: 'absolute',
                //           top: 12,
                //           right: 24
                //         }}
                //       >
                //         <IconButton
                //           sx={{visibility: index === 0 ? 'hidden' : 'visible'}}
                //           color="primary"
                //           onClick={() => homeArrayHelper.remove(index)}
                //           edge="end"
                //         >
                //           <DeleteOutlineOutlinedIcon/>
                //         </IconButton>
                //       </Grid>
                //
                //       <FormControlLabel
                //         sx={labelStyles}
                //         control={
                //           <TextField
                //             onChange={handleChange}
                //             id={`homes[${index}].name`}
                //             name={`homes[${index}].name`}
                //             variant="filled"
                //             value={home.name}
                //             error={!!homeError.name && submitCount > 0}
                //             helperText={homeError.name && submitCount > 0 ? homeError.name : ''}
                //             placeholder="House Name"
                //             sx={inputStyles}
                //             InputProps={{
                //               endAdornment: (
                //                 <InputAdornment position="end">
                //                 </InputAdornment>
                //               )
                //             }}
                //           />
                //         }
                //         label="Home Name"
                //       />
                //
                //
                //       <PlacesSearch onChange={(suggestion: Suggestion) => handlePlace(suggestion, index)} useFormControl={true}/>
                //
                //
                //       <FormControlLabel
                //         sx={labelStyles}
                //         control={
                //           <TextField
                //             onChange={handleChange}
                //             id={`homes[${index}].line1`}
                //             name={`homes[${index}].line1`}
                //             variant="filled"
                //             value={home.line1}
                //             error={!!homeError.line1 && submitCount > 0}
                //             helperText={homeError.line1 && submitCount > 0 ? homeError.line1 : ''}
                //             placeholder="Line 1"
                //             sx={inputStyles}
                //             inputProps={{
                //               autoComplete: 'shipping address-line1'
                //             }}
                //           />
                //         }
                //         label="Address"
                //       />
                //       <TextField
                //         onChange={handleChange}
                //         id={`homes[${index}].line2`}
                //         name={`homes[${index}].line2`}
                //         variant="filled"
                //         value={home.line2}
                //         placeholder="Line 2"
                //         error={!!homeError.line2 && submitCount > 0}
                //         helperText={homeError.line2 && submitCount > 0 ? homeError.line2 : ''}
                //         sx={inputStyles}
                //         inputProps={{
                //           autoComplete: 'shipping address-line2',
                //         }}
                //       />
                //
                //       <Grid container spacing={2} sx={{maxWidth: 350}}>
                //         <Grid item xs={6}>
                //           <FormControlLabel
                //             sx={labelStyles}
                //             control={
                //               <TextField
                //                 onChange={handleChange}
                //                 id={`homes[${index}].province`}
                //                 name={`homes[${index}].province`}
                //                 placeholder="Seattle"
                //                 variant="filled"
                //                 value={home.province}
                //                 error={!!homeError.province && submitCount > 0}
                //                 helperText={homeError.province && submitCount > 0 ? homeError.province : ''}
                //                 sx={inputStyles}
                //                 inputProps={{
                //                   autoComplete: 'shipping locality'
                //                 }}
                //               />
                //             }
                //             label="City"
                //           />
                //         </Grid>
                //
                //         <Grid item xs={6}>
                //           <FormControlLabel
                //             sx={labelStyles}
                //             control={
                //               <TextField
                //                 onChange={handleChange}
                //                 id={`homes[${index}].region`}
                //                 name={`homes[${index}].region`}
                //                 variant="filled"
                //                 placeholder="WA"
                //                 value={home.region}
                //                 error={!!homeError.region && submitCount > 0}
                //                 helperText={homeError.region && submitCount > 0 ? homeError.region : ''}
                //                 sx={inputStyles}
                //                 inputProps={{
                //                   autoComplete: 'shipping region'
                //                 }}
                //               />
                //             }
                //             label="State"
                //           />
                //         </Grid>
                //       </Grid>
                //
                //       <Grid container spacing={2} sx={{maxWidth: 350}}>
                //         <Grid item xs={6}>
                //           <FormControlLabel
                //             sx={labelStyles}
                //             control={
                //               <TextField
                //                 onChange={handleChange}
                //                 id={`homes[${index}].zip`}
                //                 name={`homes[${index}].zip`}
                //                 variant="filled"
                //                 placeholder="98112"
                //                 value={home.zip}
                //                 error={!!homeError.zip && submitCount > 0}
                //                 helperText={homeError.zip && submitCount > 0 ? homeError.zip : ''}
                //                 sx={inputStyles}
                //                 inputProps={{
                //                   autoComplete: 'shipping postal-code'
                //                 }}
                //               />
                //             }
                //             label="Zip"
                //           />
                //         </Grid>
                //         <Grid item xs={6}>
                //           <FormControlLabel
                //             sx={labelStyles}
                //             control={
                //               <TextField
                //                 onChange={handleChange}
                //                 id={`homes[${index}].country`}
                //                 name={`homes[${index}].country`}
                //                 variant="filled"
                //                 placeholder="US"
                //                 value={home.country}
                //                 error={!!homeError.country && submitCount > 0}
                //                 helperText={homeError.country && submitCount > 0 ? homeError.country : ''}
                //                 sx={inputStyles}
                //                 inputProps={{
                //                   autoComplete: 'shipping country'
                //                 }}
                //               />
                //             }
                //             label="Country"
                //           />
                //         </Grid>
                //       </Grid>
                //
                //       <HomeLockSubForm
                //         {...props}
                //         homeValues={home}
                //         homeErrors={homeError}
                //         fieldPath={`homes[${index}]`}
                //       />
                //
                //     </Grid>
                //   </Paper>
                // )
              }
            )}

            <Button
              variant="outlined"
              size="medium"
              sx={{width: '100%'}}
              onClick={() => homeArrayHelper.push(defaultHome())}
              startIcon={<CottageOutlinedIcon/>}
            >
              Add Home
            </Button>

          </Grid>
        }
      </FieldArray>

    </Grid>

  )
}