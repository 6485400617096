import Button from '@mui/material/Button';
import Card, { CardProps as MuiCardProps } from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import ChangeCase from 'change-case';
import React, { Fragment, useState } from 'react';
import SmartImage from '../../widgets/react-extensions/SmartImage';
import { IPlan } from '../../core/config';

export const PLAN_ICON = `${process.env.PUBLIC_URL}/assets/SVG/Icon_64x64.svg`;

export interface IPlanCard extends IPlan {
  CardProps?: MuiCardProps;
  onSelect(): void;
  loading: boolean;
  mode: 'monthly' | 'yearly';
}

export default function PlanCard(props: IPlanCard) {
  const { CardProps, details, priceYearly, priceMonthly, mode, name, loading, onSelect } = { CardProps: {} as MuiCardProps, ...props };
  const [focused, setFocused] = useState<null | {title: string; details: string}>(null);
  return (
    <Fragment>
    <Card
      {...CardProps}
      sx={{
        width: 'calc(100vw - 48px)',
        maxWidth: 345,
        transition: 'all 0.3s',
          height: '100%',
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        '&:hover': {
          boxShadow: '0 0 40px 0 rgb(0 0 0 / 20%)',
          transform: 'translateY(-5px)',
          '.plan-title': {
            color: (theme) => theme.palette.primary.main
          }
        },
        // ...(popular ? {borderBottom: (theme) => `2px solid ${theme.palette.primary.main}`} : {}),
        ...(CardProps.sx ? CardProps.sx : {})
      }}
    >
      {/*<CardActionArea*/}
      {/*  component="div"*/}
      {/*  sx={{*/}
      {/*    height: '100%',*/}
      {/*    display: 'flex',*/}
      {/*    alignItems: 'flex-start',*/}
      {/*    justifyContent: 'flex-start'*/}
      {/*  }}*/}
      {/*>*/}
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            height: 410
          }}
        >
          <SmartImage src={PLAN_ICON} style={{ marginBottom: '10px', width: 54 }}/>
          <Typography className="plan-title" variant="h3" sx={{
            textAlign: 'center',
            marginBottom: '10px',
            fontSize: 30,
            fontWeight: 700,
            lineHeight: 1.2
          }}>
            {name}
          </Typography>
          <Typography variant="h2" sx={{
            textAlign: 'center',
            marginBottom: '20px',
            fontSize: 24,
            fontWeight: 300,
            lineHeight: '100%'
          }}>
            {mode === 'monthly' ? priceMonthly : priceYearly}
          </Typography>
          <List
            sx={{
              backgroundColor: '#fff',
              marginBottom: '20px'
            }}
          >
            {details.map((detail, index: number) =>
              <ListItem
                key={`card-${ChangeCase.constantCase(name)}-${index}`}
                sx={{
                  boxShadow: 'unset'
                }}
              >
                <ListItemButton
                  sx={{padding: 0}}
                  onClick={() => setFocused(detail)}
                >
                <ListItemText
                  primary={detail.title}
                  primaryTypographyProps={{
                    color: 'textSecondary',
                    sx: {
                      fontSize: '16px',
                      textAlign: 'center'
                    }
                  }}/>
                </ListItemButton>
              </ListItem>
              )}
          </List>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="flex-end"
            sx={{
              flexGrow: 1
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onSelect();
              }}
              size="medium"
              sx={{
                minWidth: 180
              }}
              endIcon={loading && <CircularProgress sx={{color: '#fff'}} size={14}/>}
            >
              {loading ? 'Setting Up' : 'Select'}
            </Button>
          </Grid>
        </CardContent>
      {/*</CardActionArea>*/}
    </Card>
      <Dialog
        open={!!focused}
        onClose={() => setFocused(null)}
        maxWidth="xs"
      >
        <DialogTitle sx={{textAlign: 'center'}}>{focused?.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{focused?.details}</DialogContentText>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}
