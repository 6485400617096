import { StripePrice, StripeProduct } from '../../../core/schemas';

export interface EnhancedStripeProduct extends StripeProduct {
  prices: StripePrice[];
}

const prices = (product: EnhancedStripeProduct) => {
  return product.prices
    .filter((price) => typeof price.unit_amount === 'number')
    .map((price) => price.unit_amount as number);
}

export const standardPrice = (product: EnhancedStripeProduct, format: boolean) => {
  const decimal = Math.max(...prices(product));
  return format ? `$${(decimal/100).toFixed(2)}` : decimal;
}

export const discountPrice = (product: EnhancedStripeProduct, format: boolean) => {
  const decimal = Math.min(...prices(product));
  return format ? `$${(decimal/100).toFixed(2)}` : decimal;
}

export const discountPriceObj = (product: EnhancedStripeProduct): StripePrice | null => {
  let target = null;
  for (const price of product.prices) {
    if (!target && typeof price.unit_amount === 'number') {
      target = price;
    }
    if (target && typeof price.unit_amount === 'number' && price.unit_amount < (target.unit_amount as number)) {
      target = price;
    }
  }
  return target;
}


export const HOME_SECURITY_PRODUCTS: {test: EnhancedStripeProduct[], live: EnhancedStripeProduct[]} = {
  "test": [
    {
      "id": "prod_L9mMOYxgiMnRYg",
      "object": "product",
      "active": true,
      "attributes": [],
      "created": 1644938319,
      "description": "WelcomeHome installation of Ring Video Doorbell 3 and a Schlage Encode Smart WiFi Deadbolt to bring smart security to your front door.",
      "images": [
        "https://files.stripe.com/links/MDB8YWNjdF8xS0t6cU9HcGxMZm52NnZXfGZsX3Rlc3RfSnpqWmhVVWtzU2RIY3QyMFl3eHVTM2FR009mUXpCL2"
      ],
      "livemode": false,
      "metadata": {},
      "name": "Video Ring Doorbell 3 & Statin Schlage Encode Smart WiFi Deadbolt",
      "package_dimensions": null,
      "shippable": null,
      "statement_descriptor": null,
      "tax_code": "txcd_99999999",
      "type": "service",
      "unit_label": null,
      "updated": 1644938515,
      "url": null,
      "prices": [
        {
          "id": "price_1KTSqlGplLfnv6vW8cH0dGLC",
          "object": "price",
          "active": true,
          "billing_scheme": "per_unit",
          "created": 1644938515,
          "currency": "usd",
          "livemode": false,
          "lookup_key": null,
          "metadata": {},
          "nickname": "Ring.com Discounted Price",
          "product": "prod_L9mMOYxgiMnRYg",
          "recurring": null,
          "tax_behavior": "exclusive",
          "tiers_mode": null,
          "transform_quantity": null,
          "type": "one_time",
          "unit_amount": 32999,
          "unit_amount_decimal": "32999"
        },
        {
          "id": "price_1KTSncGplLfnv6vWyGHmKI9j",
          "object": "price",
          "active": true,
          "billing_scheme": "per_unit",
          "created": 1644938320,
          "currency": "usd",
          "livemode": false,
          "lookup_key": null,
          "metadata": {},
          "nickname": "Wholesale Price",
          "product": "prod_L9mMOYxgiMnRYg",
          "recurring": null,
          "tax_behavior": "exclusive",
          "tiers_mode": null,
          "transform_quantity": null,
          "type": "one_time",
          "unit_amount": 42998,
          "unit_amount_decimal": "42998"
        }
      ]
    },
    {
      "id": "prod_L9mOk59AA6nBxC",
      "object": "product",
      "active": true,
      "attributes": [],
      "created": 1644938460,
      "description": "WelcomeHome installation of Ring Video Doorbell 3 and a Schlage Encode Smart WiFi Deadbolt to bring smart security to your front door.",
      "images": [
        "https://files.stripe.com/links/MDB8YWNjdF8xS0t6cU9HcGxMZm52NnZXfGZsX3Rlc3RfUVJFOXZIcUxTSEg4eGpaYkFWd05jbnFr00CcZapQQU"
      ],
      "livemode": false,
      "metadata": {},
      "name": "Video Doorbell 3 & Aged Bronze Schlage Encode Smart WiFi Deadbolt",
      "package_dimensions": null,
      "shippable": null,
      "statement_descriptor": null,
      "tax_code": "txcd_99999999",
      "type": "service",
      "unit_label": null,
      "updated": 1644938493,
      "url": null,
      "prices": [
        {
          "id": "price_1KTSptGplLfnv6vWW2cDi4hu",
          "object": "price",
          "active": true,
          "billing_scheme": "per_unit",
          "created": 1644938461,
          "currency": "usd",
          "livemode": false,
          "lookup_key": null,
          "metadata": {},
          "nickname": "Ring.com discount",
          "product": "prod_L9mOk59AA6nBxC",
          "recurring": null,
          "tax_behavior": "exclusive",
          "tiers_mode": null,
          "transform_quantity": null,
          "type": "one_time",
          "unit_amount": 32999,
          "unit_amount_decimal": "32999"
        },
        {
          "id": "price_1KTSptGplLfnv6vWFCdJTfuc",
          "object": "price",
          "active": true,
          "billing_scheme": "per_unit",
          "created": 1644938461,
          "currency": "usd",
          "livemode": false,
          "lookup_key": null,
          "metadata": {},
          "nickname": "Ring.com wholesale price",
          "product": "prod_L9mOk59AA6nBxC",
          "recurring": null,
          "tax_behavior": "exclusive",
          "tiers_mode": null,
          "transform_quantity": null,
          "type": "one_time",
          "unit_amount": 42998,
          "unit_amount_decimal": "42998"
        }
      ]
    }
  ],
  "live": [
    {
      "id": "prod_L9mQx8MHU8inod",
      "object": "product",
      "active": true,
      "attributes": [],
      "created": 1644938572,
      "description": "WelcomeHome installation of Ring Video Doorbell 3 and a Schlage Encode Smart WiFi Deadbolt to bring smart security to your front door.",
      "images": [
        "https://files.stripe.com/links/MDB8YWNjdF8xS0t6cU9HcGxMZm52NnZXfGZsX3Rlc3RfUVJFOXZIcUxTSEg4eGpaYkFWd05jbnFr00CcZapQQU"
      ],
      "livemode": true,
      "metadata": {},
      "name": "Video Doorbell 3 & Aged Bronze Schlage Encode Smart WiFi Deadbolt",
      "package_dimensions": null,
      "shippable": null,
      "statement_descriptor": null,
      "tax_code": "txcd_99999999",
      "type": "service",
      "unit_label": null,
      "updated": 1644938573,
      "url": null,
      "prices": [
        {
          "id": "price_1KTSrhGplLfnv6vWI23gljbQ",
          "object": "price",
          "active": true,
          "billing_scheme": "per_unit",
          "created": 1644938573,
          "currency": "usd",
          "livemode": true,
          "lookup_key": null,
          "metadata": {},
          "nickname": "Ring.com wholesale price",
          "product": "prod_L9mQx8MHU8inod",
          "recurring": null,
          "tax_behavior": "exclusive",
          "tiers_mode": null,
          "transform_quantity": null,
          "type": "one_time",
          "unit_amount": 42998,
          "unit_amount_decimal": "42998"
        },
        {
          "id": "price_1KTSrhGplLfnv6vWYZ3M5DSd",
          "object": "price",
          "active": true,
          "billing_scheme": "per_unit",
          "created": 1644938573,
          "currency": "usd",
          "livemode": true,
          "lookup_key": null,
          "metadata": {},
          "nickname": "Ring.com discount",
          "product": "prod_L9mQx8MHU8inod",
          "recurring": null,
          "tax_behavior": "exclusive",
          "tiers_mode": null,
          "transform_quantity": null,
          "type": "one_time",
          "unit_amount": 32999,
          "unit_amount_decimal": "32999"
        }
      ]
    },
    {
      "id": "prod_L9mQOdOeFuYMme",
      "object": "product",
      "active": true,
      "attributes": [],
      "created": 1644938577,
      "description": "WelcomeHome installation of Ring Video Doorbell 3 and a Schlage Encode Smart WiFi Deadbolt to bring smart security to your front door.",
      "images": [
        "https://files.stripe.com/links/MDB8YWNjdF8xS0t6cU9HcGxMZm52NnZXfGZsX3Rlc3RfSnpqWmhVVWtzU2RIY3QyMFl3eHVTM2FR009mUXpCL2"
      ],
      "livemode": true,
      "metadata": {},
      "name": "Video Ring Doorbell 3 & Statin Schlage Encode Smart WiFi Deadbolt",
      "package_dimensions": null,
      "shippable": null,
      "statement_descriptor": null,
      "tax_code": "txcd_99999999",
      "type": "service",
      "unit_label": null,
      "updated": 1644938577,
      "url": null,
      "prices": [
        {
          "id": "price_1KTSrlGplLfnv6vWhrVjI3hJ",
          "object": "price",
          "active": true,
          "billing_scheme": "per_unit",
          "created": 1644938577,
          "currency": "usd",
          "livemode": true,
          "lookup_key": null,
          "metadata": {},
          "nickname": "Wholesale Price",
          "product": "prod_L9mQOdOeFuYMme",
          "recurring": null,
          "tax_behavior": "exclusive",
          "tiers_mode": null,
          "transform_quantity": null,
          "type": "one_time",
          "unit_amount": 42998,
          "unit_amount_decimal": "42998"
        },
        {
          "id": "price_1KTSrlGplLfnv6vWX2AuFVFM",
          "object": "price",
          "active": true,
          "billing_scheme": "per_unit",
          "created": 1644938577,
          "currency": "usd",
          "livemode": true,
          "lookup_key": null,
          "metadata": {},
          "nickname": "Ring.com Discounted Price",
          "product": "prod_L9mQOdOeFuYMme",
          "recurring": null,
          "tax_behavior": "exclusive",
          "tiers_mode": null,
          "transform_quantity": null,
          "type": "one_time",
          "unit_amount": 32999,
          "unit_amount_decimal": "32999"
        }
      ]
    }
  ]
} as any;