import { IProfile } from '@jumpstart/core';

import { getFunctions, httpsCallable } from 'firebase/functions';
import { FirebaseHandlers } from '../core/firebase';
import { StripeInvoice, StripeInvoiceItem, StripePrice, StripeProduct, StripeSubscription } from '../core/schemas';


const functions = getFunctions();

export interface ICustomerCreateInput {
  profileId: string;
  phone?: string;
  email?: string;
  production: boolean;
}

export interface ICustomerInfoInput {
  customerId: string;
  production: boolean;
}

export interface ICustomerInfoOutput {
  customer: any;
  paymentMethods: any;
  subscriptions: StripeSubscription[];
  invoices: StripeInvoice[];
}

export interface ICustomerCreateOutput {
  customer: { id: string };
  status: 'created' | 'existed';
}

export interface IInvoiceRetrieveInput {
  production: boolean;
  invoiceId: string;
}

export interface ISubscriptionCreateInput {
  customerId: string;
  priceId: string;
  production: boolean;
  additionalPriceIds?: string[];
}

export interface ISubscriptionCreateOutput {
  subscriptionId: string;
  clientSecret: string;
}

export interface ISubscriptionCancelInput {
  subscriptionId: string;
  production: boolean;
}

export interface ISubscriptionCancelOutput {
  subscription: any;
}

export interface ISubscriptionUpdateInput {
  subscriptionId: string;
  priceId: string;
  production: boolean;
}

export interface ISubscriptionUpdateOutput {
  subscription: any;
}

export interface ISubscriptionListInput {
  customerId: string;
  production: boolean;
}

export interface ISubscriptionListOutput {
  subscriptions: {
    data: any[];
    url: string;
    has_more: boolean;
  }
}

export interface ISubscriptionRetrieveInput {
  subscriptionId: string;
  production: boolean;
}

export interface ISubscriptionRetrieveOutput {
  subscription: StripeSubscription;
  product: StripeProduct | null;
  price: StripePrice | null;
}


const customerCreateFn = httpsCallable<ICustomerCreateInput, ICustomerCreateOutput>(functions,
  'stripe-customer-create');
const customerInfoFn = httpsCallable<ICustomerInfoInput, ICustomerInfoOutput>(functions, 'stripe-customer-info');
const invoiceRetrieveFn = httpsCallable<IInvoiceRetrieveInput, StripeInvoice>(functions, 'stripe-invoice-retrieve');
const subscriptionCreateFn = httpsCallable<ISubscriptionCreateInput, ISubscriptionCreateOutput>(functions,
  'stripe-subscription-create');
const subscriptionCancelFn = httpsCallable<ISubscriptionCancelInput, ISubscriptionCancelOutput>(functions,
  'stripe-subscription-cancel');
const subscriptionUpdateFn = httpsCallable<ISubscriptionUpdateInput, ISubscriptionUpdateOutput>(functions,
  'stripe-subscription-update');
const subscriptionListFn = httpsCallable<ISubscriptionListInput, ISubscriptionListOutput>(functions,
  'stripe-subscription-list');
const subscriptionRetrieveFn = httpsCallable<ISubscriptionRetrieveInput, ISubscriptionRetrieveOutput>(functions,
  'stripe-subscription-retrieve');

export interface IAddInvoiceItemInput {
  production: boolean;
  invoiceId?: string;
  customerId: string;
  description: string;
  priceId: string;
}

export interface IPendingInvoiceItemInput {
  production: boolean;
  customerId: string;
}

export interface IRemoveInvoiceItemInput {
  invoiceItemId: string;
  production: boolean
}

const invoiceItemAddFn = httpsCallable<IAddInvoiceItemInput, StripeInvoiceItem>(functions, 'stripe-invoice-items-add');
const invoiceItemRemoveFn = httpsCallable<IRemoveInvoiceItemInput, void>(functions, 'stripe-invoice-items-remove');
const invoicePendingItemsFn = httpsCallable<IPendingInvoiceItemInput, StripeInvoiceItem[]>(functions,
  'stripe-invoice-items-pending');

export default class StripeService {

  public static customerId(profile: IProfile): string | null {
    const customerIdKey = process.env.REACT_APP_ENV === 'production' ? 'stripeCustomerId' : 'stripeCustomerIdDev';
    if (profile && profile.payment && (profile.payment as any)[customerIdKey]) {
      return (profile.payment as any)[customerIdKey];
    } else {
      return null;
    }
  }

  public static createCustomer(input: Omit<ICustomerCreateInput, 'production'>): Promise<ICustomerCreateOutput> {
    if (!input.email && !input.phone) {
      throw new Error('Either phone or email must be provided');
    }
    return FirebaseHandlers.run<ICustomerCreateInput, ICustomerCreateOutput>(
      {production: process.env.REACT_APP_ENV === 'production', ...input},
      customerCreateFn
    );
  }

  public static infoCustomer(input: Omit<ICustomerInfoInput, 'production'>): Promise<ICustomerInfoOutput> {
    return FirebaseHandlers.run<ICustomerInfoInput, ICustomerInfoOutput>(
      {production: process.env.REACT_APP_ENV === 'production', ...input},
      customerInfoFn
    );
  }

  public static retrieveInvoice(input: Omit<IInvoiceRetrieveInput, 'production'>): Promise<StripeInvoice> {
    return FirebaseHandlers.run<IInvoiceRetrieveInput, StripeInvoice>(
      {production: process.env.REACT_APP_ENV === 'production', ...input},
      invoiceRetrieveFn
    );
  }

  public static createSubscription(input: Omit<ISubscriptionCreateInput, 'production'>): Promise<ISubscriptionCreateOutput> {
    return FirebaseHandlers.run<ISubscriptionCreateInput, ISubscriptionCreateOutput>(
      {production: process.env.REACT_APP_ENV === 'production', ...input},
      subscriptionCreateFn
    );
  }

  public static cancelSubscription(input: Omit<ISubscriptionCancelInput, 'production'>): Promise<ISubscriptionCancelOutput> {
    return FirebaseHandlers.run<ISubscriptionCancelInput, ISubscriptionCancelOutput>(
      {production: process.env.REACT_APP_ENV === 'production', ...input},
      subscriptionCancelFn
    );
  }

  public static updateSubscription(input: Omit<ISubscriptionUpdateInput, 'production'>): Promise<ISubscriptionUpdateOutput> {
    return FirebaseHandlers.run<ISubscriptionUpdateInput, ISubscriptionUpdateOutput>(
      {production: process.env.REACT_APP_ENV === 'production', ...input},
      subscriptionUpdateFn
    );
  }

  public static listSubscriptions(input: Omit<ISubscriptionListInput, 'production'>): Promise<ISubscriptionListOutput> {
    return FirebaseHandlers.run<ISubscriptionListInput, ISubscriptionListOutput>(
      {production: process.env.REACT_APP_ENV === 'production', ...input},
      subscriptionListFn
    );
  }

  public static retrieveSubscription(input: Omit<ISubscriptionRetrieveInput, 'production'>): Promise<ISubscriptionRetrieveOutput> {
    return FirebaseHandlers.run<ISubscriptionRetrieveInput, ISubscriptionRetrieveOutput>(
      {production: process.env.REACT_APP_ENV === 'production', ...input},
      subscriptionRetrieveFn
    );
  }

  public static addInvoiceItem(input: Omit<IAddInvoiceItemInput, 'production'>): Promise<StripeInvoiceItem> {
    return FirebaseHandlers.run<IAddInvoiceItemInput, StripeInvoiceItem>(
      {production: process.env.REACT_APP_ENV === 'production', ...input},
      invoiceItemAddFn
    );
  }

  public static removeInvoiceItem(input: Omit<IRemoveInvoiceItemInput, 'production'>): Promise<void> {
    return FirebaseHandlers.run<IRemoveInvoiceItemInput, void>(
      {production: process.env.REACT_APP_ENV === 'production', ...input},
      invoiceItemRemoveFn
    );
  }

  public static listPendingItems(input: Omit<IPendingInvoiceItemInput, 'production'>): Promise<StripeInvoiceItem[]> {
    return FirebaseHandlers.run<IPendingInvoiceItemInput, StripeInvoiceItem[]>(
      {production: process.env.REACT_APP_ENV === 'production', ...input},
      invoicePendingItemsFn
    );
  }

}
