import { getFunctions, httpsCallable } from 'firebase/functions';
import { FirebaseHandlers } from '../core/firebase';




export interface ILocationResult {
  neighborhood?: string;

  // Zipcode
  postal_code?: string;

  // City
  locality?: string;

  // State
  administrative_area_level_1?: string;

  // County
  administrative_area_level_2?: string;

  // Country
  country?: string;

  error?: string;
  code?: string;
}

const functions = getFunctions();
const locationFn = httpsCallable<{ zipcode?: string; lat?: number; long?: number}, ILocationResult>(functions, 'utils-location');


export class UtilsService {

  public static locationFromZip(zipcode: string): Promise<ILocationResult> {
    return FirebaseHandlers.run<{ zipcode: string; }, ILocationResult>({ zipcode }, locationFn);
  }

  public static locationFromLatLong(lat: number, long: number): Promise<ILocationResult> {
    return FirebaseHandlers.run<{ lat: number; long: number }, ILocationResult>({ lat, long }, locationFn);
  }

}
