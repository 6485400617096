import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { IWHServiceLine, SERVICELINES } from '../../core/config';
import ServicesList from '../../widgets/ServicesList';

export interface IServicesModal {
  open: boolean;

  onClose(): void;
}

export default function ServicesModal(props: IServicesModal) {
  const { open, onClose } = props;
  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll={'paper'}
      aria-labelledby="services-dialog-title"
      aria-describedby="services-dialog-description"
    >
      <DialogTitle id="services-dialog-title">
        Available Services
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            // color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          padding: 0
        }}
      >
        <DialogContentText
          id="services-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
          sx={{
            padding: 0
          }}
        >
          <ServicesList promoting={true}/>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
