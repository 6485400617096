import { IProfile } from '@jumpstart/core';
import { Context, createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';

// CONTEXTS
export const IsAdminContext: Context<boolean> = createContext<boolean>(false);
export const ProfileContext: Context<IProfile | undefined> = createContext<IProfile | undefined>(undefined);

// Handlers
export const useIsAdmin = () => useContext(IsAdminContext);
export const useProfile = () => useContext(ProfileContext);
export const useQuery = () => new URLSearchParams(useLocation().search);
