import { MathUtils } from '@jumpstart/core';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useEffect, useRef, useState } from 'react';

export interface ILoadingBackdrop {
  open: boolean;
  text: string;
  onClick?(): void;
}

export default function LoadingBackdrop(props: ILoadingBackdrop) {
  const {open, onClick, text} = props;
  const [progress, setProgress] = useState(10);
  const [variant, setVariant] = useState<'determinate' | 'indeterminate'>('determinate');
  const timer = useRef(-1);

  useEffect(() => {
    setVariant('determinate');
    setProgress(0);
    window.clearInterval(timer.current);
    timer.current = window.setInterval(() => {
      const delta = MathUtils.randomInt(1, 7);
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + delta >= 100 ? 100 : prevProgress + delta));
    }, 300);
    return () => {
      window.clearInterval(timer.current);
    };
  }, [open]);

  useEffect(() => {
    if (progress >= 100) {
      setVariant('indeterminate');
    }
  }, [progress]);

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, marginBottom: '0px !important' }}
      open={open}
      onClick={onClick}
    >
      {/*<CircularProgress color="inherit" variant="determinate" value={progress}/>*/}
      <Grid container direction="column" alignItems="center" justifyContent="center">

      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress color="inherit" variant={variant} value={progress}/>
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {variant === 'determinate' && <Typography variant="caption" component="div" color="secondary">
            {`${Math.round(progress)}%`}
          </Typography>}
        </Box>
      </Box>

        <Typography variant="caption" sx={{color: '#fff'}}>{text}</Typography>
      </Grid>
    </Backdrop>
  )
}