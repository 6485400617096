import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import ButtonBase from '@mui/material/ButtonBase';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import React, { Component } from 'react';

interface IErrorBoundaryState {
  hasError: boolean;
  error?: Error | null;
  showStack: boolean;
}

export interface IErrorBoundary {

}

export default class ErrorBoundary extends Component<IErrorBoundary, IErrorBoundaryState> {
  public static COMMON_ERRORS = {
    "ref.current.get is not a function": "Firebase is not being wired properly"
  }
  constructor(props: IErrorBoundary) {
    super(props);
    this.state = { hasError: false, showStack: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    console.error('Error:', error);
    console.error(JSON.parse(JSON.stringify(error)));
    return { hasError: true, error: error };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    console.error(error, errorInfo);
    console.error(JSON.parse(JSON.stringify(error)));
    // logErrorToMyService(error, errorInfo);
  }

  toggleStack(): void {
    this.setState({ ...this.state, showStack: !this.state.showStack });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Alert severity="error">
          <AlertTitle>{this.state.error ? this.state.error.name : 'Error'}</AlertTitle>
          <Typography
            variant="body1">{this.state.error ? this.state.error.message : 'An error has occurred'}</Typography>
          <Collapse in={this.state.showStack}>
            <Typography variant="caption">{this.state.error ? this.state.error.stack : ''}</Typography>
          </Collapse>
          <ButtonBase aria-label="error-stack" onClick={() => this.toggleStack()} style={{width: '100%'}}>
            {
              !this.state.showStack
                ? <KeyboardArrowDownIcon fontSize="inherit"/>
                : <KeyboardArrowUpIcon fontSize="inherit"/>
            }
          </ButtonBase>
        </Alert>
      );
    }

    return this.props.children;
  }

}
