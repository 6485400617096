import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {
  ConfirmationResult,
  linkWithCredential,
  PhoneAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  UserCredential
} from 'firebase/auth';
import { collection, doc, getDoc, getDocs, query, setDoc, where } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import { COLORS } from '../../../core/theme';
import { ISignupSchema } from '../schema';
import CodeConfirmationForm from './CodeConfirmationForm';
import PhoneForm from './PhoneForm';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../../../core/firebase';

export interface IPhoneSignup extends ISignupSchema {
  label: string;
  phone?: string | null;
  alt?: boolean;
}

/**
 * 1. Enter Phone Number
 * 2. Enter Confirmation Number
 * 3. If user already existed before
 *    * If user was anonymous => link account
 *    * If user was anonymous => but account exists, ask if this user is you
 *      * if user is you then, if yes, merge any existing data over
 */
export default function PhoneSignUp(props: IPhoneSignup) {
  const { label, onSuccess, onError, phone, alt } = props;
  const [user] = useAuthState(auth);
  const captchaRef = useRef<HTMLDivElement>(null);
  const [sentSMS, setSentSMS] = useState<boolean>(false);
  const [error, setError] = useState<null | Error>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult | null>(null);

  const handleExistingUser = (verificationId: string, verificationCode: string) => {
    if (!user) {
      return;
    }
    linkWithCredential(user, PhoneAuthProvider.credential(verificationId, verificationCode))
      .then(() => {
        console.log('Linked User');
        // @TODO merge user data
      });
  }

  const handleSubscribeUser = async (result: UserCredential) => {
    if (!result.user) {
      console.error('Cannot subscribe user');
      return;
    }

    // Do not subscribe user who already has a subscribed number
    if (result.user.phoneNumber) {
      const q = query(collection(db, "wh-sms-subscribers"), where("phone", "==", result.user.phoneNumber));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.docs.length > 0) {
        console.error(`Already have a user subscribed with phone ${result.user.phoneNumber}`);
        return;
      }
    }

    setDoc(doc(db, "wh-sms-subscribers", result.user.uid), {
      email: result.user.email,
      phone: result.user.phoneNumber,
      start: new Date().toISOString(),
      uid: result.user.uid,
      end: null
    })
      .catch((err: Error) => {
        console.error('Failed to subscribe user', err);
      });
  }

  const handleConfirmation = (confirmationCode: string) => {
    if (!confirmationResult) {
      setError(new Error('No confirmation result or confirmation code'));
      return;
    }
    console.log('Confirming...');
    confirmationResult.confirm(confirmationCode)
      .then((result: UserCredential) => {
        if (result.user) {
          setSuccess('Logged in');
          // if (!result.additionalUserInfo?.isNewUser && (!user || user.isAnonymous)) {
          //   handleExistingUser(confirmationResult.verificationId, confirmationCode);
          // }
          handleSubscribeUser(result);
          if (onSuccess) {
            onSuccess(result, confirmationResult.verificationId, confirmationCode);
          }
        } else {
          const error = new Error('No user found');
          setError(error);
        }
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        // @TODO implement
        console.error(error);
        setSentSMS(false);
        setConfirmationResult(null);
        setError(error);
      });
  };

  const handleSignIn = (phone: string) => {
    console.log('Attempt Sign In', phone);
    if (!captchaRef.current) {
      console.error('No captcha container found');
      return;
    }
    const prevRecaptcha: HTMLElement | null = document.getElementById('wh-recaptcha');
    if (prevRecaptcha) {
      prevRecaptcha.remove();
    }

    const newreCaptcha = document.createElement('div');
    newreCaptcha.setAttribute('id', 'wh-recaptcha');
    captchaRef.current.appendChild(newreCaptcha);
    const targetCaptcha = document.getElementById('wh-recaptcha');
    if (!targetCaptcha) {
      throw new Error('No captcha');
    }
    const verifier = new RecaptchaVerifier(targetCaptcha, {
      size: 'invisible',
      callback: (response: any) => {
        console.info('Invisible Captcha', response);
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // onCaptcha();
        const targetCaptcha = document.getElementById('wh-recaptcha');
        if (targetCaptcha) {
          targetCaptcha.remove();
        }
        setSentSMS(true);
      },
      'expired-callback': () => {
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
        console.error('Captcha Expired');
        setSentSMS(false);
      }
    }, auth);
    signInWithPhoneNumber(auth, phone, verifier)
      .then((cr: ConfirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        setConfirmationResult(cr);
        setError(null);
      }).catch((error) => {
      // Error; SMS not sent
      // ...
      console.error(error);
      setError(error);
      setConfirmationResult(null);
      setSentSMS(false);
    });
  };

  const handleReset = () => {
    setSentSMS(false);
    setConfirmationResult(null);
  };

  useEffect(() => {
    if (error && onError) {
      onError(error);
    }
  }, [error]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        maxWidth: '550px',
        width: '100%',
        '& > :not(:last-child)': {
          marginBottom: 3
        }
      }}
    >
      <div id="recaptcha-container" ref={captchaRef}/>

      {
        sentSMS ?
          <Typography
            sx={{
              color: alt ? COLORS.gray3 : '#FFF',
              textAlign: 'center',
              fontFamily: 'Inter',
              padding: '12px 0px'
            }}
            variant="body2"
          >
            Check your phone and enter the confirmation number
          </Typography>
          : null
      }

      <PhoneForm onSubmit={handleSignIn} wait={sentSMS} showTerms={false} label={label} phone={phone} alt={alt}/>

      <Collapse in={sentSMS} style={{ transitionDelay: '500ms', width: '100%' }}>
        <Grid container direction="column" style={{ width: '100%' }} justifyContent="center" alignItems="center">
          <CodeConfirmationForm onSubmit={handleConfirmation} onReset={handleReset} margin={3} alt={alt}/>
        </Grid>
      </Collapse>

      <Collapse in={!!error}>
        <Alert severity="error"
               onClose={() => setError(null)}>{error ? error.message : ''} {error && (error as any).code ? '- ' + (error as any).code : ''}</Alert>
      </Collapse>

      <Collapse in={!!success}>
        <Alert severity="success" onClose={() => setSuccess(null)}>{success ? success : ''}</Alert>
      </Collapse>
    </Grid>
  );
}
