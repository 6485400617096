import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import SmartImage from '../../widgets/react-extensions/SmartImage';
import UseCaseImages2 from './UseCaseImages2';

export default function WHHeader(props: {showUseCases?: boolean; showTitle?: boolean; logo?: string}) {
  const {showUseCases, showTitle, logo} = {showUseCases: true, showTitle: true, logo: 'Logo_White.png', ...props};
  console.log('Props', props, showTitle);
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >

      <SmartImage src={`${process.env.PUBLIC_URL}/assets/PNG/${logo}`}
                  style={{ height: 70, marginTop: '12px' }}/>
      {showTitle && <Typography variant="subtitle1" component="div" sx={{ color: '#fff', textAlign: 'center', marginTop: 1 }}>
        <u style={{ fontWeight: 'bold' }}>Your</u> Dedicated House Manager
      </Typography>}

      {showUseCases && <UseCaseImages2/>}

      {/*{showUseCases && <UseCaseImages*/}
      {/*  width={412}*/}
      {/*  images={*/}
      {/*    [*/}
      {/*      `${process.env.PUBLIC_URL}/assets/promos/landscaper.png`,*/}
      {/*      `${process.env.PUBLIC_URL}/assets/promos/pool.png`,*/}
      {/*      `${process.env.PUBLIC_URL}/assets/promos/winter.png`*/}
      {/*    ]*/}
      {/*  }*/}
      {/*/>}*/}
    </Grid>
  )
}
