import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export interface ICompanyHeader {
  desktop: boolean;
  open: boolean;

  onToggle(): void;
}

export default function CompanyHeader(props: ICompanyHeader) {
  const {desktop, open, onToggle} = props;
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      wrap="nowrap"
      sx={{...(desktop ? {} : {backgroundColor: '#fff', zIndex: 5, padding: '24px'})}}
    >
      <Grid item>
        <Grid
          container
          direction="row"
          alignItems="center"
          wrap="nowrap"
          sx={{
            '& > :not(:last-child)': {
              marginRight: 2
            }
          }}
        >
          <Avatar
            alt="WelcomeHome"
            src={`${process.env.PUBLIC_URL}/assets/PNG/Icon_64x64.png`}
            sx={{
              boxShadow: (theme) => theme.shadows[3],
              objectFit: 'cover',
              width: 32,
              height: 32
            }}
          />
          <Typography variant="caption">WelcomeHome</Typography>
          {process.env.REACT_APP_ENV !== 'production' &&
              <Chip label="Test" color="primary" size="small" sx={{padding: '0px', height: 18}}/>}
        </Grid>
      </Grid>

      {!desktop && <Grid item>
          <Button
              disableRipple
              size="medium"
              onClick={onToggle}
              sx={{
                padding: 0,
                fontSize: 13,
                color: (theme) => theme.palette.text.secondary
              }}
              endIcon={<ExpandMoreIcon
                sx={{
                  transition: 'transform .25s cubic-bezier(.35,.25,.1,1)',
                  transform: open ? 'rotate(180deg)' : 'rotate(0deg)'
                }}
              />}
          >
            {open ? 'Close' : 'Details'}
          </Button>
      </Grid>}

    </Grid>
  )
}