import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { UserCredential } from 'firebase/auth';
import React from 'react';
import InfoTip from '../../../../widgets/material-extensions/InfoTip';
import PhoneSignUp from '../../../signin/phone-signup/PhoneSignUp';

export interface IQuickPhoneSignin {
  desktop: boolean;
  initialPhone: string;
  onSuccess?(result: UserCredential, verificationId?: string, verificationCode?: string): void;
  onError?(error: Error): void;
}
export default function QuickPhoneSignin(props: IQuickPhoneSignin) {
  const {onSuccess, onError, initialPhone, desktop} = props;

  return (
    <Grid item sx={{zIndex: 2, position: 'relative'}}>
    <Grid
      container
      direction="column"
      alignItems="center"
      sx={{
        paddingTop: desktop ? 12 : 'unset',
        '& > :not(:last-child)': {
          marginBottom: 4
        }
      }}
    >
        <InfoTip title="Your dedicated house manager will connect with you via phone">
          <Typography variant="body1">Confirm your phone to setup membership</Typography>
        </InfoTip>
        <PhoneSignUp label="Submit" onSuccess={onSuccess} onError={onError} phone={initialPhone} alt={true}/>
    </Grid>
    </Grid>
  )
}