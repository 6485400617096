import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card, { CardProps } from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { memo, useEffect, useState } from 'react';
import { COLORS } from '../../../../core/theme';
import { IMAGE_PLACEHOLDER } from '../../../../core/utils';
import { discountPrice, EnhancedStripeProduct, standardPrice } from '../home-security-products';


interface IProductCard extends CardProps {
  onClick(): void;

  product: EnhancedStripeProduct;
}

function MiniProductCard(props: IProductCard) {
  const {onClick, product} = props;
  return (
    <Card
      {...props}
      sx={{
        display: 'flex',
        maxWidth: 350,
        width: '100%',
        ...(props.sx ? props.sx : {})
      }}
    >
      <CardActionArea sx={{display: 'flex', width: '100%', padding: '14px 6px'}} onClick={onClick}>
        <Grid container direction="column" alignItems="center" sx={{position: 'relative', overflow: 'hidden'}}>
          <CardMedia
            component="img"
            sx={{width: '100%', height: 100, transform: 'scale(1.5)'}}
            image={product.images[0] ? product.images[0] : IMAGE_PLACEHOLDER}
          />
          <Grid item sx={{
            position: 'absolute',
            bottom: 0,

            backgroundColor: COLORS.gray6,
            borderRadius: '16px',
            padding: '4px 12px'
          }}>
            <Typography component="div" variant="subtitle2" color="primary">
              {discountPrice(product, true)}
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', minWidth: 190}}>
          <Typography component="div" variant="subtitle1">
            {product.name}
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  )
}

function ProductCard(props: IProductCard) {
  const {onClick, product} = props;

  const [hovering, setHovering] = useState(false);

  return (
    <Card
      {...props}
      sx={{
        display: 'flex',
        maxWidth: 350,
        width: '100%',
        ...(props.sx ? props.sx : {})
      }}
    >
      <CardActionArea
        sx={{display: 'flex', flexDirection: 'column', width: '100%'}}
        onClick={onClick}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <Box sx={{overflow: 'hidden', width: '100%'}}>
          <CardMedia
            component="img"
            sx={{
              width: '100%',
              height: 220,
              transition: 'transform .2s ease',
              transform: !hovering ? 'scale(1.5)' : 'scale(1.7)',
              '&:hover': {
                transform: 'scale(1.7)',
              }
            }}
            image={product.images[0] ? product.images[0] : IMAGE_PLACEHOLDER}
          />
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
          <CardContent sx={{flex: '1 0 auto'}}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center"
                  sx={{
                    '& > :not(:last-child)': {
                      marginBottom: 0
                    }
                  }}
            >
              <Grid container direction="row" alignItems="center">
                <Typography component="div" variant="subtitle1" color="primary" sx={{marginRight: 1}}>
                  {discountPrice(product, true)}
                </Typography>
                <Typography component="div" variant="subtitle2" color="text.secondary"
                            sx={{textDecoration: 'line-through'}}>
                  {standardPrice(product, true)}
                </Typography>
              </Grid>
              <Typography component="div" variant="h5">
                {product.name}
              </Typography>

            </Grid>
            <Typography variant="body2" color="text.secondary" component="div" sx={{pt: 3}}>
              {product.description}
            </Typography>
          </CardContent>
        </Box>
      </CardActionArea>
    </Card>
  )
}

export interface IProductCardSelect {
  selected?: string;
  options: EnhancedStripeProduct[];

  onSelect(option: EnhancedStripeProduct | null): void;
}

function ProductCardSelect(props: IProductCardSelect) {
  const {options, onSelect} = props;
  const [selected, setSelected] = useState<EnhancedStripeProduct | null>(null);
  const [open, setOpen] = useState(false);
  const desktop = useMediaQuery('(min-width:600px)');

  const handleSelect = (product: EnhancedStripeProduct) => {
    setOpen(false);
    setSelected(product);
    onSelect(product);
  }

  useEffect(() => {
    if (props.selected) {
      const index = options.findIndex((p => p.id === props.selected));
      if (index > -1) {
        setSelected(options[index]);
      }
    }
  }, []);

  return (
    <Grid container direction="column" alignItems="center">
      {selected
        ? <MiniProductCard onClick={() => {
          setOpen(!open);
          onSelect(null)
          setSelected(null);
        }} product={selected}/>
        : (
          <Button variant="outlined" size="small" color="inherit" onClick={() => {
            setOpen(true);
            onSelect(null);
            setSelected(null);
          }}>
            Add Digital Lock
          </Button>
        )
      }
      <Dialog scroll="paper" open={open} onClose={() => setOpen(false)} fullScreen>
        <DialogTitle>
          <Grid container direction="row" alignItems="flex-start" justifyContent="space-between" wrap="nowrap">
            <Grid container direction="column" alignItems={desktop ? 'center' : 'flex-start'}>
              <Typography variant="h2">Select Digital Lock</Typography>
              <Typography variant="body1" color="text.secondary">WelcomeHome will provide installation at your
                convenience</Typography>
            </Grid>
            <IconButton onClick={() => setOpen(false)}>
              <ClearIcon/>
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={8}
            sx={{
              flexGrow: 1,
              padding: 6,
              justifyContent: 'center'
            }}
          >
            {options.map((product: EnhancedStripeProduct) => (
                <Grid item key={`product-select-${product.id}`}>
                  <ProductCard
                    elevation={1} onClick={() => handleSelect(product)} product={product}/>
                </Grid>
              )
            )}

          </Grid>
        </DialogContent>
      </Dialog>

    </Grid>
  );
}

export default memo(ProductCardSelect);
