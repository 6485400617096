export const getDomain = () => {
  switch(process.env.REACT_APP_ENV) {
    case 'development':
      return `http://localhost:3000`;
    case 'test':
      return `https://welcomehome-test.web.app`;
    case 'production':
      return `https://join-welcomehome.com`
    default:
      return `http://localhost:3000`;
  }
}