// Mui-error

export const scrollToErrors = () => {
  const collection = document.getElementsByClassName('Mui-error')
  console.log('COLLECTION', collection);
  if (collection.length > 0) {
    collection[0].scrollIntoView({
      block: 'center',
      inline: 'center',
    });
  }
}