import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { doc, UpdateData, updateDoc } from 'firebase/firestore';
import { User } from 'firebase/auth';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { object, string } from 'yup';
import 'yup-phone-lite';
import { db } from '../../core/firebase';
import { useProfile } from '../../core/hooks';
import ZipcodeField from './ZipcodeField';


const schema = object().shape({
  zipcode: string()
    .required('Zipcode is required')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(5, 'Must be exactly 5 digits')
    .max(5, 'Must be exactly 5 digits')
});

export default function WHAvailabilityForm(props: { user: User; approxZipcode: string | null; onSubmit: (zipcode: string | null) => void }) {
  const { user, approxZipcode, onSubmit } = props;
  const [error, setError] = useState<null | Error>(null);
  const profile = useProfile();

  const updateZip = (_zipcode: string | null) => {
    const profileRef = doc(db, `wh-profiles`, user.uid);
    const data: UpdateData<any> = {
      'address.zip': _zipcode
    }
    updateDoc(profileRef, data)
      .catch((err: Error) => {
        console.error('Failed to update profile', err.message);
      });
  }

  const handleSubmit = (values: { zipcode: string | null }) => {
    const { zipcode } = values;
    setError(null);
    if (!user) {
      console.error('No user');
      setError(new Error('No user found, something unexpected happened'));
      return;
    }
    if (!zipcode) {
      setError(new Error('No zipcode was provided'));
      return;
    }
    onSubmit(zipcode);
    // Save zipcode for later
    updateZip(zipcode);
  };

  useEffect(() => {
    // If already have zipcode go to next page, skip if not production
    if (approxZipcode && user && profile) {
      if (!profile.address.zip) {
        updateZip(approxZipcode);
      }

    }
  }, [approxZipcode, user, profile]);

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        zipcode: approxZipcode
      } as { zipcode: string | null }}
      validationSchema={schema}
    >
      {(formikProps) =>
        <Form style={{ width: '100%' }}>
          <Grid
            container
            direction="column"
            wrap="nowrap"
            justifyContent="center"
            alignItems="center"
            sx={{
              padding: 4,
              '& > :not(:last-child)': {
                marginBottom: 4
              }
            }}
          >
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
              Check availability:
            </Typography>

            <ZipcodeField
              schema={schema}
              onAutoZipcode={(zipcode: string) => handleSubmit({ zipcode: zipcode })}
              {...formikProps}
            />

            <Button
              id="submit-form"
              type="submit"
              variant="contained"
              color="primary"
              size="medium"
              sx={{
                minWidth: 250,
                marginTop: 6,
              }}
            >
              Submit
            </Button>

          </Grid>
        </Form>
      }
    </Formik>
  );
}
