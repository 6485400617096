import { Severity } from '@jumpstart/core';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { Firestore, getFirestore } from 'firebase/firestore';
import {
  connectFunctionsEmulator,
  FunctionsError,
  getFunctions,
  HttpsCallable,
  HttpsCallableResult
} from 'firebase/functions';
import { GlobalError } from './schemas';

console.log(process.env);
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

if (process.env.REACT_APP_ENV === 'development') {
  console.log('Firebase Config', firebaseConfig);
}

export const app = initializeApp(firebaseConfig);
export const db: Firestore = getFirestore(app);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const functions = getFunctions(app);

auth.useDeviceLanguage();

if (process.env.REACT_APP_ENV === 'development') {
  connectFunctionsEmulator(functions, 'localhost', 5001);
}


export class FirebaseHandlers {

  public static async run<Input = any, Output = any>(
    input: Input,
    callable: HttpsCallable<Input, Output>
  ): Promise<Output> {
    return callable(input)
      .then((result: HttpsCallableResult<Output>) => result.data)
      .catch((error: FunctionsError) => {
        throw this.httpsError(error);
      });
  }

  public static httpsError(error: Partial<FunctionsError>): GlobalError {
    console.error(error);
    return {
      severity: Severity.CRITICAL,
      message: error.message ? error.message : 'Unknown error',
      ...error
    } as GlobalError;
  }

}

