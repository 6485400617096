import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  signInAnonymously,
  signInWithEmailAndPassword,
  signInWithPopup,
  UserCredential
} from 'firebase/auth';

const provider = new GoogleAuthProvider();

export const loginInAnonymously = async (): Promise<UserCredential> => {
  try {
    console.log('Sign in anonymously...');
    const auth = getAuth();
    return signInAnonymously(auth);
  } catch (err: any) {
    console.error(err);
    throw err;
  }
};

export const loginWithGoogle = async (): Promise<UserCredential> => {
  try {
    const auth = getAuth();
    return signInWithPopup(auth, provider);
  } catch (err: any) {
    console.error(err);
    throw err;
  }

};

export const loginWithEmail = async (email: string, password: string) => {
  try {
    const auth = getAuth();
    return signInWithEmailAndPassword(auth, email, password);
  } catch (err: any) {
    console.error(err);
    throw err;
  }
};

export const createUserWithEmail = async (email: string, password: string) => {
  try {
    const auth = getAuth();
    return createUserWithEmailAndPassword(auth, email, password);
  } catch (err: any) {
    console.error(err);
    throw err;
  }
};
