import Backdrop from '@mui/material/Backdrop';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import { useRef, useState } from 'react';
import {
  StripeInvoice,
  StripeInvoiceItem,
  StripePrice,
  StripeProduct,
  StripeSubscription
} from '../../../core/schemas';
import { EnhancedStripeProduct } from './home-security-products';
import CompanyHeader from './widgets/CompanyHeader';
import OrderEstimate from './widgets/OrderEstimate';
import ProductSummary from './widgets/ProductSummary';


export interface ISubscriptionData {
  product: StripeProduct | null;
  price: StripePrice | null;
  items: EnhancedStripeProduct[];
}

export interface ISubscriptionEstimateDetails extends ISubscriptionData {
  desktop: boolean;
  loading: boolean;
}



export default function SubscriptionEstimateDetails(props: ISubscriptionEstimateDetails) {
  const {loading, desktop, price, product, items} = props;
  const [showDetails, setShowDetails] = useState(false);
  const ref = useRef<any>(null);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      sx={{
        '@keyframes blinker': {
          '0%': { opacity: 1 },
          '50%': { opacity: 0.5 },
          '100%': { opacity: 1 },
        },
        ...(loading ? {
          animationName: 'blinker',
          animationDuration: '1s',
          animationTimingFunction: 'linear',
          animationIterationCount: 'infinite',
          // backgroundColor: 'rgba(255,0,0,0.3)'
        } : {}),
        ...(desktop ? {
          position: 'fixed',
          left: 0,
          width: '50%',
        } : {
        }),
        paddingTop: desktop ? 12 : 'unset',
        '& > *': {
          maxWidth: 380,
        },
        '& > :not(:last-child):not(.backdrop)': {
          marginBottom: desktop ? 12 : 4
        }
      }}
    >
      <Grid container ref={ref} sx={{
        ...(desktop ? {} : {position: 'fixed', top: 0, zIndex: 4})
      }}>
        <CompanyHeader desktop={desktop} open={showDetails} onToggle={() => setShowDetails(!showDetails)}/>
        <Slide direction="down" in={showDetails} container={ref.current} mountOnEnter unmountOnExit>
          <Grid container direction="column" sx={{backgroundColor: '#fff', zIndex: 4, padding: '12px 24px 12px 0px'}}>
            {
              price &&
              product &&
                <OrderEstimate
                    items={items}
                    desktop={desktop}
                    product={product}
                    price={price}
                />
            }
          </Grid>
        </Slide>
      </Grid>
      <Backdrop className="backdrop" open={showDetails} sx={{zIndex: 3}} onClick={() => setShowDetails(false)}/>
      {!desktop && <div style={{width: '100%', height: 76, backgroundColor: 'red'}}/>}

      <Grid
        container
        direction="column"
        sx={{
          padding: desktop ? 'unset' : '0 24px',
          '& > :not(:last-child)': {
            marginBottom: 8
          }
        }}
      >
        {
          price &&
          product &&
            <ProductSummary
                desktop={desktop}
                onToggle={() => setShowDetails(!showDetails)}
                product={product}
                price={price}
            />
        }
        {
          price &&
          product &&
          desktop &&
            <OrderEstimate
                items={items}
                desktop={desktop}
                product={product}
                price={price}
            />
        }
      </Grid>
    </Grid>

  )

}