import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { Fragment, useState } from 'react';

export interface IInfoTip extends TooltipProps {

}

export default function InfoTip(props: IInfoTip) {
  const desktop = useMediaQuery('(min-width:600px)');
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    if (!desktop) {
      setOpen(true);
    }
  }
  return (
    <Fragment>
      <Tooltip {...props}>
        <Grid container direction="row" alignItems="flex-start" justifyContent="center" wrap="nowrap">
          {props.children}
          <IconButton size="small" onClick={handleClick} disableRipple>
            <InfoOutlinedIcon sx={{fontSize: 16}} fontSize="small"/>
          </IconButton>
        </Grid>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="xs"
      >
        <DialogContent>
          <DialogContentText>{props.title}</DialogContentText>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}